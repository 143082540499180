import { FC, useCallback, useState } from 'react';
import { Button } from 'src/components/Button';
import { TicTacToeItem } from 'src/components/TicTacToeItem';
import { createMatrix, winnerCalc } from 'src/utils/ticTacToe';
import { Info } from 'src/components/Info';
import cn from 'classnames';

import { ReactComponent as PlayIcon } from '../../../../assets/icons/kit/play.svg';
import TicTacToeBgIcon from '../../../../assets/icons/info/info-bg-6.svg';
import { Container } from 'src/containers';

const cols = 8;
const rows = 8;
const numToWin = 4;

export type TicTacToeItemVariants = 'X' | 'O' | '-1' | '';

export const GameTicTacToeDesc: FC = () => {
  const [turn, setTurn] = useState<TicTacToeItemVariants>('O');
  const [winner, setWinner] = useState<TicTacToeItemVariants>('');
  const [winnerList, setWinnerList] = useState<number[][]>([]);
  const [matrix, setMatrix] = useState<TicTacToeItemVariants[][]>(createMatrix(rows, cols));

  const handleSetValue = (lastRow: number, lastCol: number) => {
    matrix[lastRow][lastCol] = turn;
    setMatrix(matrix);
    setTurn(prev => (prev === 'X' ? 'O' : 'X'));
    const winner = winnerCalc(matrix, rows, cols, numToWin, lastRow, lastCol);
    setWinner(winner.winner as TicTacToeItemVariants);
    setWinnerList(winner.winnerList);
  };

  const handleRestart = () => {
    setMatrix(createMatrix(rows, cols));
    setTurn('O');
    setWinner('');
  };

  const createBoard = useCallback(() => {
    const board = [];

    for (let r = 0; r < rows; r++) {
      const row = [];
      for (let c = 0; c < cols; c++) {
        const isEnd = winner === 'X' || winner === 'O';
        const isColored = winnerList.find(winnerItem => winnerItem[0] === r && winnerItem[1] === c);

        row.push(
          <TicTacToeItem
            row={r}
            col={c}
            key={`tic-tac-toe-item-${r} + ${c}`}
            setValue={handleSetValue}
            value={matrix[r][c]}
            selected={matrix[r][c] === 'X' || matrix[r][c] === 'O'}
            disable={isEnd}
            colored={isEnd && !!isColored}
          />,
        );
      }
      board.push(
        <div className="flex w-full" key={`tic-tac-toe-row-${r}`}>
          {row}
        </div>,
      );
    }

    return <div>{board}</div>;
  }, [handleSetValue, winner, matrix, cols, rows]);

  return (
    <Container classNames="gap-y-4">
      {createBoard()}
      {!winner ? (
        <div className="py-3 border-t border-violet-400 w-full">
          <div className="font-inter-600 text-sm mb-2">Игроки:</div>
          {['O', 'X'].map((type, i) => (
            <div
              key={`tic-tac-poe-player-${i}`}
              className={cn({ 'bg-white': turn === type }, 'px-3 py-1.5 rounded flex')}
            >
              <PlayIcon className={cn({ 'opacity-0': turn !== type }, 'fill-red-200 mr-2')} />
              <div className="text-black-100 text-xs">Игрок {i + 1}</div>
              <div className="ml-auto h-4 w-4">
                <TicTacToeItem
                  row={0}
                  col={0}
                  setValue={handleSetValue}
                  value={type as TicTacToeItemVariants}
                  selected
                  disable
                  colored={false}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Info
          title={winner === '-1' ? 'Ничья!' : `Игрок ${winner === 'O' ? 1 : 2} победил!`}
          subtitle={winner !== '-1' ? `Ваши очки\nРекорд 3 002` : ''}
          rightText={winner !== '-1' ? '2 912' : ''}
          bg={TicTacToeBgIcon}
        />
      )}
      {winner ? (
        <Button
          className="rounded-lg h-12 mb-2 mt-auto"
          text="Сыграть еще раз"
          variant="violetOutline"
          onClick={handleRestart}
        />
      ) : null}
    </Container>
  );
};
