import { TicTacToeItemVariants } from '../pages/Games/GameTicTacToe/GameTicTacToeDesc';
import { ConstructorItemVariants } from '../pages/Games/GameConstructor/GameConstructorDesc';

/**
 * @description
 * check if 2d array have an empty cell
 * @param {TicTacToeItemVariants[][] | ConstructorItemVariants[][]} matrix 2d array
 * @param {number} rowsNum number of rows
 * @param {number} colsNum number of columns
 * @returns {boolean} return true if empty cell was found, and false if not.
 */
const haveEmptyCell = (
  matrix: TicTacToeItemVariants[][] | ConstructorItemVariants[][],
  rowsNum: number,
  colsNum: number,
): boolean => {
  let empty = false;
  for (let x = 0; x < rowsNum; x++) {
    for (let y = 0; y < colsNum; y++) {
      const element: any = matrix[x][y];
      if (!element) {
        empty = true;
        break;
      }
    }
    if (empty) break;
  }
  return empty;
};

/**
 * @description
 * check winner horizontal, vertical and diagonal
 * @param {TicTacToeItemVariants[][] | ConstructorItemVariants[][]} matrix 2d array
 * @param {number} rowsNum number of rows
 * @param {number} colsNum number of columns
 * @param {number} numToWin the number of matching to win
 * @param {number} lastRow the row number of the square player click
 * @param {number} lastCol the column number of the square player click
 * @returns {TicTacToeItemVariants} return the winner, X or O or '' if no one win.
 */

export const winnerCalc = (
  matrix: TicTacToeItemVariants[][] | ConstructorItemVariants[][],
  rowsNum: number,
  colsNum: number,
  numToWin: number,
  lastRow: number,
  lastCol: number,
): { winner: TicTacToeItemVariants | ConstructorItemVariants; winnerList: number[][] } => {
  let winner: TicTacToeItemVariants | ConstructorItemVariants = '';
  let match = 0;
  let winnerList: number[][] = [];
  const lastValue: TicTacToeItemVariants | ConstructorItemVariants = matrix[lastRow][lastCol];

  //check Horizontal
  for (let c = 0; c < colsNum; c++) {
    const currentValue = matrix[lastRow][c];
    if (currentValue === lastValue) {
      match++;
      winnerList.push([lastRow, c]);
    } else {
      match = 0;
      winnerList = [];
    }
    if (match === numToWin) {
      winner = lastValue;
      break;
    }
  }
  if (winner !== '') return { winner, winnerList };

  match = 0;
  winnerList = [];

  //check Vertical
  for (let r = 0; r < rowsNum; r++) {
    const currentValue = matrix[r][lastCol];
    if (currentValue === lastValue) {
      match++;
      winnerList.push([r, lastCol]);
    } else {
      match = 0;
      winnerList = [];
    }

    if (match === numToWin) {
      winner = lastValue;
      break;
    }
  }

  if (winner !== '') return { winner, winnerList };

  //check diagonal top-left to bottom-right - include middle
  for (let r = 0; r <= rowsNum - numToWin; r++) {
    let rowPosition = r;
    match = 0;
    winnerList = [];
    for (let column = 0; column < colsNum && rowPosition < rowsNum; column++) {
      const currentValue = matrix[rowPosition][column];

      if (currentValue === lastValue) {
        match++;
        winnerList.push([rowPosition, column]);
      } else {
        match = 0;
        winnerList = [];
      }
      if (match === numToWin) {
        winner = lastValue;
        break;
      }
      rowPosition++;
    }
    if (winner !== '') break;
  }
  if (winner !== '') return { winner, winnerList };

  //check diagonal top-left to bottom-right - after middle
  for (let c = 1; c <= colsNum - numToWin; c++) {
    let columnPosition = c;
    match = 0;
    winnerList = [];
    for (let row = 0; row < rowsNum && columnPosition < colsNum; row++) {
      const currentValue = matrix[row][columnPosition];
      if (currentValue === lastValue) {
        match++;
        winnerList.push([row, columnPosition]);
      } else {
        match = 0;
        winnerList = [];
      }
      if (match === numToWin) {
        winner = lastValue;
        break;
      }
      columnPosition++;
    }
    if (winner !== '') break;
  }
  if (winner !== '') return { winner, winnerList };

  //check diagonal bottom-left to top-right - include middle
  for (let r = rowsNum - 1; r >= rowsNum - numToWin - 1; r--) {
    let rowPosition = r;
    match = 0;
    winnerList = [];
    for (let column = 0; column < colsNum && rowPosition < rowsNum && rowPosition >= 0; column++) {
      const currentValue = matrix[rowPosition][column];
      if (currentValue === lastValue) {
        match++;
        winnerList.push([rowPosition, column]);
      } else {
        match = 0;
        winnerList = [];
      }
      if (match === numToWin) {
        winner = lastValue;
        break;
      }
      rowPosition--;
    }
    if (winner !== '') break;
  }
  if (winner !== '') return { winner, winnerList };

  //check diagonal bottom-left to top-right - after middle
  for (let c = 1; c < colsNum; c++) {
    let columnPosition = c;
    match = 0;
    winnerList = [];

    for (
      let row = rowsNum - 1;
      row < rowsNum && row >= 0 && columnPosition < colsNum && columnPosition >= 1;
      row--
    ) {
      const currentValue = matrix[row][columnPosition];
      if (currentValue === lastValue) {
        match++;
        winnerList.push([row, columnPosition]);
      } else {
        match = 0;
        winnerList = [];
      }
      if (match === numToWin) {
        winner = lastValue;
        break;
      }
      columnPosition++;
    }

    if (winner !== '') break;
  }

  if (winner !== '') return { winner, winnerList };

  if (!haveEmptyCell(matrix, rowsNum, colsNum)) {
    winner = '-1';
    winnerList = [];
  }

  if (winner === '' || winner === '-1') {
    winnerList = [];
  }

  return { winner, winnerList };
};

/**
 * @description
 * create matrix
 * @param {number} rows number of rows
 * @param {number} cols number of columns
 * @returns {TicTacToeItemVariants[][]} return the matrix.
 */
export const createMatrix = (rows: number, cols: number): TicTacToeItemVariants[][] =>
  new Array(rows).fill('').map(_ => new Array(cols).fill(''));
