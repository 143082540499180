import { FC } from 'react';
import { StickerType } from 'src/config/types';
import { Button } from '../../Button';

import { ReactComponent as ClockIcon } from '../../../assets/icons/kit/clock.svg';
import { ReactComponent as StickerCountIcon } from '../../../assets/icons/kit/sticker-count.svg';
import cn from 'classnames';

interface StickerProps {
  data: StickerType;
  desc?: boolean;
}

export const Sticker: FC<StickerProps> = ({
  data: { id, image, title, stickersCount, endDate, prizes },
  desc,
}) => {
  const styles = 'flex gap-x-1 items-center';

  const sortedPrizes = prizes.sort((a, b) => a.count - b.count);
  const count = sortedPrizes[sortedPrizes.length - 1].count - stickersCount;

  return (
    <div
      className={cn(
        {
          'px-2 py-4 bg-white rounded-xl': !Boolean(desc),
        },
        'w-full flex flex-col gap-y-4',
      )}
    >
      <img src={desc ? '/static/images/sticker-2.svg' : image} alt="" className="w-full" />
      <div className="flex flex-col gap-y-2">
        <div className="font-inter-500">{title}</div>
        <div className="flex gap-x-3 text-grey-200 text-small">
          <div className={styles}>
            <ClockIcon className="w-2.5 h-2.5 fill-grey-200" />
            {endDate}
          </div>
          <div className={styles}>
            <StickerCountIcon className="w-2.5 h-2.5 fill-grey-200" />
            {`Осталось собрать наклеек: ${count}`}
          </div>
        </div>
      </div>

      {!desc ? (
        <Button
          text="Открыть  буклет"
          variant="redOutline"
          className="h-9 rounded-xl text-xs"
          href={`/stickers/desc/${id}/`}
        />
      ) : null}
    </div>
  );
};
