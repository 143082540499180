import { FC } from 'react';
import { mysteryBoxItems } from 'src/config/MOCK_DATA/mysteryBox';
import { MysteryBoxCard } from 'src/components/Cards/MysteryBoxCard';
import { Container } from 'src/containers';

export const MysteryBoxList: FC = () => {
  return (
    <Container classNames="gap-5">
      {mysteryBoxItems.map((mysteryBoxItem, i) => (
        <MysteryBoxCard key={`mystery-box-item-${i}`} data={mysteryBoxItem} />
      ))}
    </Container>
  );
};
