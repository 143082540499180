import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductsState } from './types';
import { wineTinderShopList } from 'src/config/MOCK_DATA/wineTinder';
import {
  catalogChemicalsList,
  catalogCosmeticList,
  catalogProductsList,
} from 'src/config/MOCK_DATA/catalog';

const initialState: ProductsState = {
  products: {
    products: catalogProductsList,
    cosmetics: catalogCosmeticList,
    'household-chemicals': catalogChemicalsList,
    wine: wineTinderShopList,
  },
  isLoadingProducts: true,
  error: '',
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    addItem: (state, action: PayloadAction<{ catalogId: string; productId: string }>) => {
      const { catalogId, productId } = action.payload;
      const index = state.products[catalogId]?.findIndex(el => el.id === productId);

      if (typeof index === 'number') {
        const product = state.products[catalogId][index];
        state.products[catalogId][index] = { ...product, count: product.count + 1 };
      }
    },
    removeItem: (state, action: PayloadAction<{ catalogId: string; productId: string }>) => {
      const { catalogId, productId } = action.payload;
      const index = state.products[catalogId]?.findIndex(el => el.id === productId);

      if (typeof index === 'number') {
        const product = state.products[catalogId][index];
        state.products[catalogId][index] = { ...product, count: product.count - 1 };
      }
    },
    clearItem: (state, action: PayloadAction<{ catalogId: string; productId: string }>) => {
      const { catalogId, productId } = action.payload;
      const index = state.products[catalogId]?.findIndex(el => el.id === productId);

      if (typeof index === 'number') {
        const product = state.products[catalogId][index];
        state.products[catalogId][index] = { ...product, count: 0 };
      }
    },
    clearAll: (state, action: PayloadAction<{ catalogId: string }>) => {
      const { catalogId } = action.payload;

      if (catalogId) {
        state.products[catalogId] = state.products[catalogId].map(product => ({
          ...product,
          count: 0,
        }));
      }
    },
  },
});

export const { addItem, removeItem, clearItem, clearAll } = productsSlice.actions;

const reducer = productsSlice.reducer;
export default reducer;
