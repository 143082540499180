import { FC } from 'react';
import { checkClassName } from 'src/utils';
import { Input } from 'src/components/Input';
import { Button } from 'src/components/Button';

import cn from 'classnames';

interface PromocodeProps {
  classNames?: string;
}

export const Promocode: FC<PromocodeProps> = ({ classNames }) => {
  return (
    <div
      className={cn(
        {
          'w-full': !checkClassName(classNames || '', 'w-'),
          'h-12 min-h-[3rem]': !checkClassName(classNames || '', 'h-'),
          'rounded-lg': !checkClassName(classNames || '', 'rounded-'),
        },
        'overflow-hidden flex border border-violet-300',
        classNames,
      )}
    >
      <Input placeHolder="Промокод" className="h-full w-full text-sm border-none" />
      <Button text="Применить" variant="red" className="opacity-50 h-full w-fit px-6 text-sm" />
    </div>
  );
};
