import { FC } from 'react';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { HowWork } from 'src/components/HowWork';
import { Container } from 'src/containers';

export const MysteryBoxPrice: FC = () => {
  return (
    <Container classNames="items-center gap-4">
      <div className="text-sm max-w-[16rem] text-center font-inter-500">
        Введите сумму на которую хотите купить подарок
      </div>

      <HowWork classNames="mb-8" />

      <div className="text-big-line-h mb-6 max-w-[16rem] text-center">
        Наша интеллектуальная система сгенерирует уникальное наполнение подарка в красивом боксе
      </div>

      <Input placeHolder="Введите сумму" className="w-full h-11 min-h-[2.75rem]" />

      <Button
        href="/mystery-box/list/"
        text="Далее"
        variant="red"
        className="w-full h-12 min-h-[3rem] rounded-xl mt-auto"
      />
    </Container>
  );
};
