import { FC } from 'react';
import { wineTinderShopList } from 'src/config/MOCK_DATA/wineTinder';
import { Container } from 'src/containers';
import cn from 'classnames';

export const WineTinderVoteResult: FC = () => {
  return (
    <Container>
      <div className="w-full bg-white rounded-lg">
        {wineTinderShopList.map((wine, i) => (
          <div
            key={`wine-vote-result-${i}`}
            className={cn(
              { 'border-t border-grey-300': !!i },
              'w-full p-3 flex items-center gap-2.5',
            )}
          >
            <img alt="" src={wine.img} className="min-w-[4rem] w-16 h-24 object-contain" />
            <div className="flex w-full flex-col gap-1">
              <div className="text-xs text-black-100 font-inter-600">{wine.name}</div>
              <div className="relative w-full rounded-full overflow-hidden bg-violet-200 h-2">
                <div
                  className="absolute top-0 left-0 h-full bg-purple-200"
                  style={{ width: `${i * 20}%` }}
                />
              </div>
              <div className="text-black-100 opacity-50 text-small">{`${i * 20}%`}</div>
              <div className="flex justify-between items-center">
                <div className="text-lg font-inter-700">{wine.currentPrice}</div>
                <div
                  className={cn(
                    { 'bg-purple-200': i, 'bg-violet-200': !i },
                    'rounded-lg text-white py-2 px-4 text-xs font-inter-600',
                  )}
                >
                  {`${i * 2} ${i === 1 || i === 2 ? 'голоса' : 'голосов'}`}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};
