import { FC, useMemo, useRef, useState } from 'react';
import { NavigationItemType } from 'src/config/types';
import { SquareButton } from '../SquareButton';
import cn from 'classnames';

import { ReactComponent as SmoothArrowIcon } from '../../assets/icons/kit/smooth-arrow.svg';

interface NavigationItemProps {
  data: NavigationItemType;
}

export const NavigationItem: FC<NavigationItemProps> = ({ data }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => {
    setIsOpen(prev => !prev);
  };

  const height = useMemo(
    () => (data.elements.length > 4 ? `${2 * 88 + 24 + 20}px` : `${88 + 24}px`),
    [data],
  );

  return (
    <div>
      <div
        className={cn(
          'w-full h-20 rounded-xl flex items-center justify-between px-6',
          'cursor-pointer relative overflow-hidden',
          data.bgColor,
        )}
        onClick={handleClick}
      >
        <div
          className={cn(
            'w-5/6 square rounded-[2rem] absolute -top-32 -left-20 -rotate-30',
            data.squareColor,
          )}
        />
        <div className="text-lg text-blue-300 font-inter-600 relative">{data.text}</div>
        <SmoothArrowIcon className={cn({ 'rotate-180': isOpen }, 'w-7 h-7 fill-white relative')} />
      </div>

      <div
        ref={ref}
        className="w-full transition-height duration-500 overflow-hidden"
        style={{ height: isOpen ? height : 0 }}
      >
        <div
          className={cn(
            {
              'justify-between': data.elements.length > 4,
              'justify-center': data.elements.length <= 4,
            },
            'pt-6 flex gap-5 px-4 flex-wrap align-center',
          )}
        >
          {data.elements.map((el, i) => (
            <div
              key={`${data.text}-${el.text}-${i}`}
              className="flex flex-col items-center gap-y-2 cursor-pointer"
            >
              <SquareButton {...el} />
              <div className="text-small whitespace-pre-wrap text-center">{el.text}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
