import { FC, useState } from 'react';
import { MainMenuItem } from 'src/config/types';
import { SquareButton } from 'src/components/SquareButton';
import { wineTinderShopList } from 'src/config/MOCK_DATA/wineTinder';
import { TinderClickType, TinderList } from 'src/components/TinderList';
import { useRoute } from 'src/hooks/useRoute';

import { ReactComponent as LikeIcon } from '../../../assets/icons/kit/like.svg';
import { ReactComponent as DisLikeIcon } from '../../../assets/icons/kit/dislike.svg';

export const WineTinderVoteList: FC = () => {
  const changeRoute = useRoute();

  const buttons: MainMenuItem[] = [
    {
      text: 'right',
      variant: 'green',
      icon: <LikeIcon />,
    },

    {
      text: 'left',
      variant: 'red',
      icon: <DisLikeIcon />,
    },
  ];

  const [click, setClick] = useState<TinderClickType>('');

  const onEnd = () => {
    changeRoute('/wine-tinder/vote/end/');
  };

  const handleClick = (el: MainMenuItem) => {
    setClick(el.text as TinderClickType);
  };
  return (
    <>
      <div className="w-full h-full overflow-hidden tinder-list-height relative">
        <TinderList data={wineTinderShopList} click={click} setClick={setClick} onEnd={onEnd} />
      </div>
      <div className="flex justify-center bg-violet-100 gap-8 p-3 relative">
        {buttons.map((menuItem, i) => (
          <div key={`main-menu-${i}`} className="cursor-pointer">
            <SquareButton
              size="lg"
              variant={menuItem.variant}
              icon={menuItem.icon}
              onClick={() => handleClick(menuItem)}
            />
          </div>
        ))}
      </div>
    </>
  );
};
