import { FC, useState } from 'react';
import { Button } from 'src/components/Button';
import { HowWork } from 'src/components/HowWork';
import { Container } from 'src/containers';
import { TabItemType } from 'src/config/types';
import { Tabs } from 'src/components/Tabs';
import cn from 'classnames';

export const MysteryBoxForWho: FC = () => {
  const genders: TabItemType[] = [
    { value: 'man', name: 'Для него' },
    { value: 'woman', name: 'Для нее' },
  ];

  const [selectedGender, setSelectedGender] = useState<TabItemType>(genders[0]);

  const styles = 'absolute top-0 left-0 mx-auto h-full w-full object-contain';

  return (
    <Container classNames="items-center gap-4">
      <div className="text-sm font-inter-500">Выберите для кого будет подарок</div>

      <HowWork />

      <div className="h-96 w-full relative">
        {genders.map(gender => (
          <img
            key={`mystery-box-${gender.value}`}
            src={`/static/images/${gender.value}.png`}
            alt=""
            className={cn(
              { 'opacity-0': selectedGender.value !== gender.value },
              'transition-opacity duration-500',
              styles,
            )}
          />
        ))}
      </div>

      <Tabs values={genders} value={selectedGender} setValue={setSelectedGender} />

      <Button
        href="/mystery-box/direction/"
        text="Далее"
        variant="red"
        className="w-full h-12 min-h-[3rem] rounded-xl mt-auto"
      />
    </Container>
  );
};
