import { FC, useState } from 'react';
import { Input } from 'src/components/Input';
import { Button } from 'src/components/Button';
import { FilterLine } from 'src/components/FilterLine';
import { Container } from 'src/containers';
import { Select, SelectedItemType } from 'src/components/Select';

import cn from 'classnames';

export const DeliveryType: FC = () => {
  const deliveryType: SelectedItemType[] = [{ text: 'Самовывоз' }, { text: 'Курьерская доставка' }];
  const cities: SelectedItemType[] = [{ text: 'Ростов-на-Дону' }, { text: 'Москва' }];

  const [selectedDeliveryType, setSelectedDeliveryType] = useState<SelectedItemType | undefined>(
    deliveryType[0],
  );
  const [selectedCities, setSelectedCities] = useState<SelectedItemType | undefined>(cities[0]);

  return (
    <Container classNames="gap-4">
      <FilterLine text="Тип доставки" />
      <Select
        list={deliveryType}
        onClick={setSelectedDeliveryType}
        selected={selectedDeliveryType}
      />
      <FilterLine text="Ваш город" />
      <Select list={cities} onClick={setSelectedCities} selected={selectedCities} />

      {['Улица', 'Дом', 'Квартира', 'Комментарий'].map((el, i) => (
        <Input
          key={`type-input-${i}`}
          placeHolder={el}
          className={cn({ 'h-11': el !== 'Комментарий', 'h-16': el === 'Комментарий' }, 'text-sm')}
        />
      ))}

      <Button
        href="/delivery/date/"
        text="Уточнить дату и время"
        variant="red"
        className="w-full h-12 min-h-[3rem] rounded-xl"
      />
    </Container>
  );
};
