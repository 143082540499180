import { FC } from 'react';
import { navigation } from 'src/config/data';
import { NavigationItem } from 'src/components/NavigationItem';
import { Container } from 'src/containers';

export const Home: FC = () => {
  return (
    <Container classNames="gap-y-6">
      {navigation.map(navigationIem => (
        <NavigationItem key={navigationIem.text} data={navigationIem} />
      ))}
    </Container>
  );
};
