import { FC, useState } from 'react';
import { MainMenuItem } from 'src/config/types';
import { SquareButton } from 'src/components/SquareButton';
import { wineTinderShopList } from 'src/config/MOCK_DATA/wineTinder';
import { TinderClickType, TinderList } from 'src/components/TinderList';

import { ReactComponent as LikeIcon } from '../../../assets/icons/kit/like.svg';
import { ReactComponent as DisLikeIcon } from '../../../assets/icons/kit/dislike.svg';
import { ReactComponent as HeartIcon } from '../../../assets/icons/kit/heart.svg';
import { ReactComponent as HumansIcon } from '../../../assets/icons/kit/humans.svg';

export const WineTinderList: FC = () => {
  const buttons: MainMenuItem[] = [
    {
      text: 'Нравится',
      variant: 'green',
      icon: <LikeIcon />,
    },
    {
      text: 'Избранное',
      variant: 'yellow',
      icon: <HeartIcon />,
    },
    {
      text: 'Не нравится',
      variant: 'red',
      icon: <DisLikeIcon />,
    },
    {
      text: 'Поделиться',
      variant: 'purple',
      icon: <HumansIcon />,
    },
  ];
  const data = [
    ...wineTinderShopList,
    ...wineTinderShopList,
    ...wineTinderShopList,
    ...wineTinderShopList,
  ];

  const [click, setClick] = useState<TinderClickType>('');

  const handleClick = (el: MainMenuItem) => {
    if (el.text === 'Нравится') {
      setClick('right');
    }
    if (el.text === 'Не нравится') {
      setClick('left');
    }
  };
  return (
    <>
      <div className="w-full h-full overflow-hidden tinder-list-height relative">
        <TinderList data={data} click={click} setClick={setClick} />
      </div>
      <div className="flex justify-center bg-violet-100 gap-8 p-3 relative">
        {buttons.map((menuItem, i) => (
          <div key={`main-menu-${i}`} className="flex flex-col items-center gap-y-2 cursor-pointer">
            <SquareButton
              variant={menuItem.variant}
              icon={menuItem.icon}
              onClick={() => handleClick(menuItem)}
            />
            <div className="text-small whitespace-pre-wrap">{menuItem.text}</div>
          </div>
        ))}
      </div>
    </>
  );
};
