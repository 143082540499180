import { FC, useState } from 'react';
import { Button } from 'src/components/Button';
import { Categories } from 'src/components/Categories';
import { FilterLine } from 'src/components/FilterLine';
import { Select, SelectedItemType } from 'src/components/Select';
import { useNavigate } from 'react-router-dom';
import { Container } from 'src/containers';
import { Interval } from 'src/config/types';

import cn from 'classnames';
import { MultiRangeSlider } from 'src/components/MultiRangeSlider';

export const WineTinderFilter: FC = () => {
  const colorIconStyles = 'w-3.5 h-3.5 rounded';
  const colors: SelectedItemType[] = [
    { text: 'розовый', icon: <div className={cn(colorIconStyles, 'bg-pink-100')} /> },
    { text: 'фиолетовый', icon: <div className={cn(colorIconStyles, 'bg-violet-600')} /> },
    { text: 'жёлтый', icon: <div className={cn(colorIconStyles, 'bg-yellow-200')} /> },
  ];
  const size: SelectedItemType[] = [{ text: '0.75' }, { text: '1' }, { text: '2' }];
  const country: SelectedItemType[] = [{ text: 'Италия' }, { text: 'Франция' }, { text: 'Россия' }];
  const categories = [
    'Вино',
    'Шампанское',
    'Игристое вино',
    'Коньяк',
    'Крепкие напитки',
    'Шампанское',
    'Игристое вино',
  ];
  const sugars = ['Сухое', 'Полусухое', 'Полусладкое', 'Сладкое'];
  const dish = ['Вино', 'Морепродукты', 'Мясо', 'Птица', 'Вегетарианские блюда', 'Десерты'];

  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState(['Вино']);
  const [selectedSugar, setSelectedSugar] = useState(['Сухое']);
  const [selectedDish, setSelectedDish] = useState(['Вино']);
  const [price, setPrice] = useState<Interval>({ min: 700, max: 10000 });
  const [selectedColor, setSelectedColor] = useState<SelectedItemType | undefined>(colors[0]);
  const [selectedSize, setSelectedSize] = useState<SelectedItemType | undefined>(size[0]);
  const [selectedCountry, setSelectedCountry] = useState<SelectedItemType | undefined>(country[0]);

  const handleChangePrice = (price: Interval) => {
    setPrice(price);
  };

  return (
    <Container classNames="gap-5 px-">
      <FilterLine text="Категория товара" textClassNames="px-6">
        <Categories
          uniqKey="wine-category"
          list={categories}
          selected={selectedCategory}
          setSelected={setSelectedCategory}
          classNames="px-6"
          rows={2}
        />
      </FilterLine>

      <FilterLine text="Содержание сахара" textClassNames="px-6">
        <Categories
          uniqKey="wine-sugar"
          list={sugars}
          selected={selectedSugar}
          setSelected={setSelectedSugar}
          classNames="px-6"
        />
      </FilterLine>

      <FilterLine text="Ценовой диапозон" textClassNames="px-6">
        <MultiRangeSlider
          values={price}
          initValues={{ min: 0, max: 20000 }}
          onChange={handleChangePrice}
          classNames="mx-6 mt-5"
          valueType=" ₽"
        />
      </FilterLine>

      <Select
        list={colors}
        selected={selectedColor}
        placeholder="Выберите цвет..."
        onClick={setSelectedColor}
        classNames="mx-6"
      />

      <FilterLine text="Подходит к блюдам" textClassNames="px-6">
        <Categories
          uniqKey="wine-dish"
          list={dish}
          selected={selectedDish}
          setSelected={setSelectedDish}
          classNames="px-6"
          rows={2}
        />
      </FilterLine>

      <FilterLine text="Объем" textClassNames="px-6">
        <Select list={size} selected={selectedSize} onClick={setSelectedSize} classNames="mx-6" />
      </FilterLine>

      <FilterLine text="Страна" textClassNames="px-6">
        <Select
          list={country}
          selected={selectedCountry}
          onClick={setSelectedCountry}
          classNames="mx-6"
        />
      </FilterLine>

      <div className="px-6 w-full mt-auto">
        <Button
          onClick={() => navigate(-1)}
          text="Применить"
          variant="red"
          className="w-full h-12 rounded-xl"
        />
      </div>
    </Container>
  );
};
