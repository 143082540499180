import {
  Home,
  Account,
  CatalogBasket,
  CatalogFilter,
  CatalogList,
  CatalogTypes,
  DeliveryDate,
  DeliverySuccess,
  DeliveryType,
  DeliveryCourier,
  DeliveryAddress,
  DeliveryPayment,
  GamesHome,
  GameTicTacToeStart,
  GameTicTacToeDesc,
  GameSmartestCategories,
  GameSmartestDesc,
  GameConstructorDesc,
  CouponsList,
  CouponsFilter,
  StickersList,
  StickersDesc,
  AdventCalendar,
  Achievements,
  OnlineConsultantHome,
  OnlineConsultantList,
  MysteryBoxDirection,
  MysteryBoxPrice,
  MysteryBoxList,
  MysteryBoxForWho,
  WineTinderHome,
  WineTinderFilter,
  WineTinderList,
  WineTinderVoteList,
  WineTinderVoteEnd,
  WineTinderVoteResult,
  WineTinderVoteHome,
} from '../pages';
import { RouteItem } from './types';

export const routes: RouteItem[] = [
  { path: '/', element: <Home />, withMenu: true },

  {
    path: '/account/',
    element: <Account />,
    headMenuProps: { variant: 'account', text: 'Личный кабинет' },
  },

  {
    path: '/catalog/types/',
    element: <CatalogTypes />,
    headMenuProps: { variant: 'account', text: 'Каталог товаров' },
  },
  {
    path: '/catalog/list/:id',
    element: <CatalogList />,
    headMenuProps: { variant: 'basket', text: 'Продукты питания' },
  },
  {
    path: '/catalog/filter/:id',
    element: <CatalogFilter />,
    headMenuProps: { variant: 'basket', text: 'Фильтры' },
  },
  {
    path: '/catalog/basket/:id',
    element: <CatalogBasket />,
    headMenuProps: { variant: 'account', text: 'Корзина' },
  },

  {
    path: '/delivery/address/:id',
    element: <DeliveryAddress />,
    headMenuProps: { variant: 'account', text: 'Оформление заказа' },
  },
  {
    path: '/delivery/type/:id',
    element: <DeliveryType />,
    headMenuProps: { variant: 'account', text: 'Оформление заказа' },
  },
  {
    path: '/delivery/date/:id',
    element: <DeliveryDate />,
    headMenuProps: { variant: 'account', text: 'Оформление заказа' },
  },
  {
    path: '/delivery/courier/:id',
    element: <DeliveryCourier />,
    headMenuProps: { variant: 'account', text: 'Оформление заказа' },
  },
  {
    path: '/delivery/payment/:id',
    element: <DeliveryPayment />,
    headMenuProps: { variant: 'account', text: 'Оформление заказа' },
  },
  {
    path: '/delivery/success/:id',
    element: <DeliverySuccess />,
    headMenuProps: { variant: 'basket', text: 'Оформление заказа' },
  },

  {
    path: '/games/home/',
    element: <GamesHome />,
    headMenuProps: { variant: 'games', text: 'Игры' },
  },
  {
    path: '/games/tic-tac-toe/start/',
    element: <GameTicTacToeStart />,
    headMenuProps: { variant: 'games', text: 'Крестики-нолики' },
  },
  {
    path: '/games/tic-tac-toe/desc/',
    element: <GameTicTacToeDesc />,
    headMenuProps: { variant: 'games', text: 'Крестики-нолики' },
  },
  {
    path: '/games/smartest/categories/',
    element: <GameSmartestCategories />,
    headMenuProps: { variant: 'games', text: 'Самый умный' },
  },
  {
    path: '/games/smartest/:category/',
    element: <GameSmartestDesc />,
    headMenuProps: { variant: 'games', text: 'Самый умный' },
    withId: true,
  },
  {
    path: '/games/constructor/',
    element: <GameConstructorDesc />,
    headMenuProps: { variant: 'games', text: 'Конструктор' },
  },

  {
    path: '/coupons/list/',
    element: <CouponsList />,
    headMenuProps: { variant: 'coupon', text: 'Купоны' },
  },
  {
    path: '/coupons/filter/',
    element: <CouponsFilter />,
    headMenuProps: { variant: 'coupon', text: 'Фильтры' },
  },

  {
    path: '/stickers/list/',
    element: <StickersList />,
    headMenuProps: { variant: 'sticker', text: 'Наклейки' },
  },
  {
    path: '/stickers/desc/:stickerId/',
    element: <StickersDesc />,
    headMenuProps: { variant: 'sticker', text: 'Наклейки' },
    withId: true,
  },

  {
    path: '/advent-calendar/',
    element: <AdventCalendar />,
    headMenuProps: { variant: 'calendar', text: 'Адвент календарь' },
  },

  {
    path: '/achievements/',
    element: <Achievements />,
    headMenuProps: { variant: 'achievements', text: 'Мои достижения' },
  },

  {
    path: '/mystery-box/for-who/',
    element: <MysteryBoxForWho />,
    headMenuProps: { variant: 'mysteryBox', text: 'Mystery box' },
  },
  {
    path: '/mystery-box/direction/',
    element: <MysteryBoxDirection />,
    headMenuProps: { variant: 'mysteryBox', text: 'Mystery box' },
  },
  {
    path: '/mystery-box/price/',
    element: <MysteryBoxPrice />,
    headMenuProps: { variant: 'mysteryBox', text: 'Mystery box' },
  },
  {
    path: '/mystery-box/list/',
    element: <MysteryBoxList />,
    headMenuProps: { variant: 'mysteryBox', text: 'Mystery box' },
  },

  {
    path: '/online-consultant/home/',
    element: <OnlineConsultantHome />,
    headMenuProps: { variant: 'operator', text: 'Онлайн-консультант' },
  },
  {
    path: '/online-consultant/list/',
    element: <OnlineConsultantList />,
    headMenuProps: { variant: 'operator', text: 'Онлайн-консультант' },
  },

  {
    path: '/wine-tinder/home/',
    element: <WineTinderHome />,
    headMenuProps: { variant: 'wine', text: 'Винный Тиндер' },
  },
  {
    path: '/wine-tinder/filter/',
    element: <WineTinderFilter />,
    headMenuProps: { variant: 'settings', text: 'Настройки' },
  },
  {
    path: '/wine-tinder/list/',
    element: <WineTinderList />,
    headMenuProps: { variant: 'bottle', text: 'Винный тиндер' },
    hideBottomMenu: true,
  },
  {
    path: '/wine-tinder/vote/home/',
    element: <WineTinderVoteHome />,
    headMenuProps: { variant: 'star', text: 'Голосование' },
  },
  {
    path: '/wine-tinder/vote/list/',
    element: <WineTinderVoteList />,
    headMenuProps: { variant: 'star', text: 'Голосование' },
    hideBottomMenu: true,
  },
  {
    path: '/wine-tinder/vote/end/',
    element: <WineTinderVoteEnd />,
    headMenuProps: { variant: 'star', text: 'Голосование' },
  },
  {
    path: '/wine-tinder/vote/result/',
    element: <WineTinderVoteResult />,
    headMenuProps: { variant: 'star', text: 'Результаты голосования' },
  },
];
