import { FC } from 'react';
import { Button } from 'src/components/Button';
import { Container } from 'src/containers';

import { ReactComponent as TicTacToeIcon } from '../../../../assets/icons/tic-tac-toe.svg';

export const GameTicTacToeStart: FC = () => {
  return (
    <Container classNames="gap-3">
      <TicTacToeIcon className="h-44 w-56 m-auto" />

      <Button
        className="rounded-lg h-12 mt-auto"
        text="Играть"
        variant="violetOutline"
        href="/games/tic-tac-toe/desc/"
      />
      <Button className="rounded-lg h-12" text="Меню" variant="red" href="/games/home/" />
    </Container>
  );
};
