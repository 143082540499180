import { FC, ReactNode } from 'react';
import cn from 'classnames';
import { checkClassName } from 'src/utils';

interface InputProps {
  placeHolder?: string;
  value?: string;
  onChange?: () => void;
  onClick?: () => void;
  icon?: ReactNode;
  className?: string;
}

export const Input: FC<InputProps> = ({
  placeHolder,
  onChange,
  icon,
  value,
  onClick,
  className,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className={cn(
        {
          'pr-3': !Boolean(icon),
          'border border-violet-300': !checkClassName(className || '', 'border'),
        },
        'flex items-center pl-3 bg-white rounded-md ',
        className,
      )}
    >
      <input
        placeholder={placeHolder}
        onChange={onChange}
        value={value}
        className="w-full text-xs font-inter-400"
      />
      {icon ? (
        <div
          className="cursor-pointer ml-auto h-full square flex items-center justify-center"
          onClick={handleClick}
        >
          {icon}
        </div>
      ) : null}
    </div>
  );
};
