import { StickerType } from '../types';

export const stickers: StickerType[] = [
  {
    id: 1,
    image: '/static/images/sticker-1.svg',
    title:
      'Товары для дома из коллекции SuperPuper Cook за электронные наклейки в магазине «Семерочка»',
    endDate: 'Доступно: до 31 марта',
    stickersCount: 7,
    prizes: [
      { text: 'набор бокалов', count: 10 },
      { text: 'Набор ножей', count: 20 },
      { text: 'Набор контейнеров', count: 20 },
      { text: 'Фирменная сковорода', count: 25 },
      { text: 'Набор кастрюль', count: 35 },
      { text: 'Мультиварка', count: 50 },
    ],
  },
  {
    id: 2,
    image: '/static/images/sticker-1.svg',
    title:
      'Товары для дома из коллекции SuperPuper Cook за электронные наклейки в магазине «Семерочка»',
    endDate: 'Доступно: до 31 марта',
    stickersCount: 30,
    prizes: [
      { text: 'набор бокалов', count: 10 },
      { text: 'Набор ножей', count: 20 },
      { text: 'Набор контейнеров', count: 20 },
      { text: 'Фирменная сковорода', count: 25 },
      { text: 'Набор кастрюль', count: 35 },
      { text: 'Мультиварка', count: 50 },
    ],
  },
];
