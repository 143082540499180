import { SmartestCategory, SmartestGameQuestion } from '../types';

export const smartestCategories: SmartestCategory[] = [
  {
    className: 'bg-red-200',
    href: '/games/smartest/true-false/',
    text: 'Правда\nили ложь',
  },
  {
    className: 'bg-violet-600',
    href: '/games/smartest/about-sport/',
    text: 'Про\nспорт',
  },
  {
    className: 'bg-blue-400',
    href: '/games/smartest/science-and-life/',
    text: 'Наука и\nжизнь',
  },
  {
    className: 'bg-blue-300',
    href: '/games/smartest/history-and-facts/',
    text: 'История и\nфакты',
  },
  {
    className: 'bg-green-200',
    href: '/games/smartest/animals-world/',
    text: 'Животный\nмир',
  },
  {
    className: 'bg-violet-200',
    href: '/games/smartest/cooking-world/',
    text: 'Мир\nкулинарии',
  },
];

export const smartestGameQuestions: SmartestGameQuestion = {
  'true-false': {
    question: 'Электроны крупнее, чем\nмолекулы.',
    answer: 'lie',
    description:
      'Молекула (новолатинское molecula, уменьшительное от лат. moles — масса) — наименьшая частица химического ВЕЩЕСТВА, обладающая всеми его химическими свойствами Молекула обычно состоит из двух или более атомов, характеризуется количеством входящих в неё атомных ядер и электронов, а также определённой структурой.',
  },
  'about-sport': {
    question: 'Электроны крупнее, чем\nмолекулы.',
    answer: 'lie',
    description:
      'Молекула (новолатинское molecula, уменьшительное от лат. moles — масса) — наименьшая частица химического ВЕЩЕСТВА, обладающая всеми его химическими свойствами Молекула обычно состоит из двух или более атомов, характеризуется количеством входящих в неё атомных ядер и электронов, а также определённой структурой.',
  },
  'science-and-life': {
    question: 'Электроны крупнее, чем\nмолекулы.',
    answer: 'lie',
    description:
      'Молекула (новолатинское molecula, уменьшительное от лат. moles — масса) — наименьшая частица химического ВЕЩЕСТВА, обладающая всеми его химическими свойствами Молекула обычно состоит из двух или более атомов, характеризуется количеством входящих в неё атомных ядер и электронов, а также определённой структурой.',
  },
  'history-and-facts': {
    question: 'Электроны крупнее, чем\nмолекулы.',
    answer: 'lie',
    description:
      'Молекула (новолатинское molecula, уменьшительное от лат. moles — масса) — наименьшая частица химического ВЕЩЕСТВА, обладающая всеми его химическими свойствами Молекула обычно состоит из двух или более атомов, характеризуется количеством входящих в неё атомных ядер и электронов, а также определённой структурой.',
  },
  'animals-world': {
    question: 'Электроны крупнее, чем\nмолекулы.',
    answer: 'lie',
    description:
      'Молекула (новолатинское molecula, уменьшительное от лат. moles — масса) — наименьшая частица химического ВЕЩЕСТВА, обладающая всеми его химическими свойствами Молекула обычно состоит из двух или более атомов, характеризуется количеством входящих в неё атомных ядер и электронов, а также определённой структурой.',
  },
  'cooking-world': {
    question: 'Электроны крупнее, чем\nмолекулы.',
    answer: 'lie',
    description:
      'Молекула (новолатинское molecula, уменьшительное от лат. moles — масса) — наименьшая частица химического ВЕЩЕСТВА, обладающая всеми его химическими свойствами Молекула обычно состоит из двух или более атомов, характеризуется количеством входящих в неё атомных ядер и электронов, а также определённой структурой.',
  },
};
