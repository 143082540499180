import { FC, useMemo, useState } from 'react';
import { CatalogItemType } from 'src/config/types';
import { ProductCounter } from 'src/components/ProductCounter';
import { Button } from 'src/components/Button';
import { checkClassName } from 'src/utils';
import { useParams } from 'react-router';
import { useAppDispatch } from 'src/hooks/redux';
import { addItem, removeItem } from 'src/store/products';
import cn from 'classnames';

interface ProductBigProps {
  data: CatalogItemType;
  imgClassNames?: string;
}

export const ProductBig: FC<ProductBigProps> = ({ data, imgClassNames }) => {
  const [liked, setLiked] = useState<boolean>(data.favourite || false);
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const heartSrc = useMemo(() => `/static/images/heart-${liked ? 2 : 1}.png`, [liked]);

  const setItemParams = { catalogId: id || '', productId: data.id };

  const onAdd = () => {
    dispatch(addItem(setItemParams));
  };

  const onRemove = () => {
    dispatch(removeItem(setItemParams));
  };

  return (
    <div className="p-2 flex flex-col bg-white rounded-xl">
      <div className="rounded-md overflow-hidden mb-4 relative w-full h-28">
        <img
          className={cn(
            { 'object-cover': !checkClassName(imgClassNames || '', 'object-') },
            'w-full h-full',
            imgClassNames,
          )}
          src={data.img}
          alt=""
        />
        <img
          className="absolute top-2 right-2"
          onClick={() => setLiked(prev => !prev)}
          src={heartSrc}
          alt=""
        />
        {data.discount ? (
          <div
            className={
              'p-1.5 rounded-tl-md bg-yellow-200 font-inter-700 text-small absolute right-0 bottom-0'
            }
          >
            {data.discount}
          </div>
        ) : null}
      </div>
      <div className="flex items-center gap-2 mb-1.5">
        <div className="font-inter-700">{data.currentPrice}</div>
        {data.oldPrice ? (
          <div className="opacity-50 line-through font-inter-500 text-sm">{data.oldPrice}</div>
        ) : null}
      </div>
      <div className="flex items-center mb-4">
        <div>
          <div className="text-black-100 font-inter-600 text-xs">{data.name}</div>
          <div className="text-black-100 opacity-50 text-small">{data.description}</div>
        </div>
        {data.count ? (
          <ProductCounter
            count={data.count}
            onAdd={onAdd}
            onRemove={onRemove}
            classNames="ml-auto"
          />
        ) : null}
      </div>
      <Button
        text="В корзину"
        {...(data.count && { href: '/catalog/basket/' })}
        {...(!data.count && { onClick: onAdd })}
        variant={data.count ? 'red' : 'redOutline'}
        className="text-small h-9 rounded-xl mt-auto"
      />
    </div>
  );
};
