import { FC } from 'react';
import { Button } from '../Button';

interface DeliveryItemSmallProps {
  title: string;
  text: string;
  onClick?: () => void;
}

export const DeliveryItemSmall: FC<DeliveryItemSmallProps> = ({ title, text, onClick }) => {
  return (
    <div
      className="w-full px-2.5 py-4 rounded-xl border border-violet-400 flex items-center bg-white"
      onClick={() => onClick && onClick()}
    >
      <div className="text-small">
        <div className="font-inter-600 mb-1.5">{title}</div>
        <div>{text}</div>
      </div>
      <Button
        text="Изменить"
        variant="redOutline"
        className="rounded-md text-small h-7 w-20 ml-auto"
      />
    </div>
  );
};
