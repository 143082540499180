import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'src/components/Button';
import { HowWork } from 'src/components/HowWork';

import cn from 'classnames';
import { Container } from 'src/containers';

export const MysteryBoxDirection: FC = () => {
  const [active, setActive] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const length = 4;

  const minSwipeDistance = 10;
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const onTouchStart = (e: any) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchEnd = useCallback(() => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      setActive(active === length - 1 ? 0 : active + 1);
    }

    if (isRightSwipe) {
      setActive(active === 0 ? length - 1 : active - 1);
    }
  }, [active, setActive, touchStart, touchEnd, minSwipeDistance]);

  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);

  const text = ['Уход и крема', 'Здоровье и спорт', 'Косметика', 'Сертификаты'];

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const curActive = document.querySelector(`#mystery-box-slider-${active}`) as HTMLDivElement;
      if (curActive && ref.current) {
        const left = (document.documentElement.clientWidth - 224) / 2;
        ref.current.scrollTo({ behavior: 'smooth', left: curActive.offsetLeft - left });
      }
    }
  }, [active]);

  return (
    <>
      <Container classNames="items-center gap-4 h-auto">
        <div className="text-sm font-inter-500">Выберите для кого будет подарок</div>

        <HowWork />
      </Container>

      <div
        className="flex w-full mx-auto gap-3 items-center overflow-hidden"
        ref={ref}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {Array.from({ length }).map((_, i) => (
          <>
            {i === 0 ? <div className="w-56 min-w-[14rem]" /> : null}
            <div
              key={`mystery-box-slider-${i}`}
              id={`mystery-box-slider-${i}`}
              onClick={() => setActive(i)}
              className={cn(
                {
                  '': i === active,
                  'scale-90 opacity-50': i !== active,
                },
                'w-56 min-w-[14rem] flex flex-col gap-4 items-center',
              )}
            >
              <div className={cn('w-full square p-5 bg-white rounded-3xl')}>
                <img src={`/static/images/mystery-${i + 1}.png`} alt="" className="w-full square" />
              </div>
              <div className="text-sm font-inter-500">{text[i]}</div>
            </div>
            {i === length - 1 ? <div className="w-56 min-w-[14rem]" /> : null}
          </>
        ))}
      </div>

      <Container classNames="h-auto mt-auto">
        <Button
          href="/mystery-box/price/"
          text="Выбрать"
          variant="red"
          className="w-full h-12 rounded-xl"
        />
      </Container>
    </>
  );
};
