import { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

interface SmartestCategory {
  href: string;
  text: string;
  className: string;
}

export const SmartestCategory: FC<SmartestCategory> = ({ href, text, className }) => {
  return (
    <Link
      to={href}
      className={cn('square rounded-xl p-3 flex items-end relative overflow-hidden', className)}
    >
      <div className="absolute rotate-26 bg-yellow-200 w-[120%] h-[120%] rounded-extra-large right-0 top-[60%]" />
      <div className="text-lg-small whitespace-pre-wrap font-inter-600 relative">{text}</div>
    </Link>
  );
};
