import { FC } from 'react';
import { CatalogItemType } from 'src/config/types';
import { Categories } from 'src/components/Categories';

import cn from 'classnames';

interface TinderCardProps {
  data: CatalogItemType;
  classNames?: string;
}

export const TinderCard: FC<TinderCardProps> = ({ data, classNames }) => {
  const categories = ['Вино', 'Красное', 'Полусладкое', 'Италия', 'Мясо', 'Птица', 'До 1 000 ₽'];

  return (
    <div
      className={cn(
        'bg-white w-full p-5 flex flex-col shadow-tinder pointer-events-none',
        classNames,
      )}
    >
      <div className="grow w-full mb-4 flex items-center h-80">
        <img className="h-full w-full object-contain" src={data.img} alt="" />
      </div>
      <div className="mt-auto mb-4 text-black-100 font-inter-600">{data.name}</div>
      <Categories uniqKey="shop-category" list={categories} rows={2} />
    </div>
  );
};
