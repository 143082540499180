import { Dispatch, FC, SetStateAction } from 'react';

import cn from 'classnames';
import { ReactComponent as MinusIcon } from '../../assets/icons/kit/minus.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/kit/plus.svg';
import { ReactComponent as ShopIcon } from '../../assets/icons/kit/shop.svg';

interface ProductCounterProps {
  count: number;
  classNames?: string;
  small?: boolean;
  onAdd: () => void;
  onRemove: () => void;
}

export const ProductCounter: FC<ProductCounterProps> = ({
  count,
  classNames,
  small,
  onRemove,
  onAdd,
}) => {
  const handlePlus = () => {
    onAdd();
  };

  const handleMinus = () => {
    onRemove();
  };

  return (
    <div
      className={cn(
        { 'py-2 px-4 rounded-lg bg-yellow-200': Boolean(small) },
        classNames,
        'flex items-center gap-2',
      )}
    >
      <MinusIcon className="w-1.5 h-1.5" onClick={handleMinus} />
      {small ? (
        <div className="text-xs text-blue-300 font-inter-600 min-w-[1rem] text-center">{count}</div>
      ) : (
        <div className="relative">
          <div
            className={
              'absolute top-0.5 -right-0.5 w-2 h-2 rounded-full font-inter-600 ' +
              'text-extra-small text-yellow-200 bg-red-200 flex ' +
              'items-center justify-center'
            }
          >
            {count}
          </div>
          <ShopIcon className="w-4 h-4 fill-blue-300" />
        </div>
      )}
      <PlusIcon className="w-1.5 h-1.5" onClick={handlePlus} />
    </div>
  );
};
