import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'src/components/Button';
import { SquareButton, SquareButtonProps } from 'src/components/SquareButton';
import parse from 'html-react-parser';

import { ReactComponent as BottleIcon } from '../../../assets/icons/kit/bottle.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/kit/settings.svg';
import { ReactComponent as StarIcon } from '../../../assets/icons/kit/star.svg';
import cn from 'classnames';
import { Container } from 'src/containers';

export const WineTinderHome: FC = () => {
  const [active, setActive] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const length = 4;

  const minSwipeDistance = 10;
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const onTouchStart = (e: any) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchEnd = useCallback(() => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      setActive(active === length - 1 ? 0 : active + 1);
    }

    if (isRightSwipe) {
      setActive(active === 0 ? length - 1 : active - 1);
    }
  }, [active, setActive, touchStart, touchEnd, minSwipeDistance]);

  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (ref.current) {
        const left = document.documentElement.clientWidth;
        ref.current.scrollTo({ behavior: 'smooth', left: active * left });
      }
    }
  }, [active]);

  const text = `<b>Винный Tinder</b> — отличный способ выбрать алкоголь для любого мероприятия быстро и легко.`;

  const variants: (SquareButtonProps & { text: string })[] = [
    {
      href: '/wine-tinder/filter/',
      variant: 'red',
      icon: <SettingsIcon />,
      text: 'Настройки',
    },
    {
      href: '/wine-tinder/vote/home/',
      variant: 'yellow',
      icon: <StarIcon />,
      text: 'Голосование',
    },
    {
      href: '/catalog/list/wine/',
      variant: 'purple',
      icon: <BottleIcon />,
      text: 'Каталог',
    },
  ];

  return (
    <Container classNames="pt-5 pb-5 items-center relative overflow-hidden">
      <div className="rotate-45 bg-violet-300 rounded-[12.5rem] absolute w-[50rem] square -top-[34rem]" />

      <div
        className={
          'w-36 h-36 rounded-full border-2 border-white bg-blue-300 ' +
          'flex items-center justify-center relative'
        }
      >
        <img className="" alt="" src="/static/images/wine-tinder.svg" />
      </div>

      <div className="flex w-full justify-between relative px-12">
        {variants.map((el, i) => (
          <div
            key={`wine-tinder-navigation-${el.href}`}
            className={cn(
              { 'mt-12': i === 1 },
              'flex flex-col items-center gap-y-2 cursor-pointer',
            )}
          >
            <SquareButton {...el} />
            <div className="text-small whitespace-pre-wrap text-center">{el.text}</div>
          </div>
        ))}
      </div>

      <div
        className="w-full overflow-hidden flex mt-auto"
        ref={ref}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {Array.from({ length }).map((_, i) => (
          <div
            className="min-w-full flex justify-center"
            key={`wine-tinder-slider-${i}`}
            id={`wine-tinder-slider-${i}`}
          >
            <div className="w-60 text-center text-blue-300 leading-4 b">{parse(text)}</div>
          </div>
        ))}
      </div>

      <div className="flex mx-auto mt-3 gap-2.5">
        {Array.from({ length }).map((_, i) => (
          <div
            key={`wine-tinder-slider-nav-${i}`}
            className={cn(
              { 'bg-purple-200': i === active, 'bg-black-400': i !== active },
              'w-2.5 h-2.5 rounded-full cursor-pointer',
            )}
            onClick={() => setActive(i)}
          />
        ))}
      </div>

      <Button
        href="/wine-tinder/list/"
        text="Выбрать алкоголь вместе с друзьями"
        variant="red"
        className="w-full h-12 min-h-[3rem] mt-8 rounded-xl"
      />
    </Container>
  );
};
