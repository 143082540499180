import { FC } from 'react';
import { Button } from 'src/components/Button';
import { ConsultantCard } from 'src/components/Cards/ConsultantCard';
import { Container } from 'src/containers';

export const OnlineConsultantList: FC = () => {
  return (
    <Container classNames="gap-4">
      <ConsultantCard />

      <Button
        href="/"
        text="Отправить чат-боту"
        variant="red"
        className="w-full h-12 min-h-[3rem] rounded-xl mt-auto"
      />
    </Container>
  );
};
