import { FC } from 'react';
import cn from 'classnames';

interface CategoryItemProps {
  text: string;
  selectedList: string[] | boolean;
  onClick: (arg: string) => void;
}

export const CategoryItem: FC<CategoryItemProps> = ({ text, selectedList, onClick }) => {
  const iseSelected =
    typeof selectedList === 'boolean' ? selectedList : selectedList.includes(text);

  return (
    <div
      onClick={() => onClick(text)}
      className={cn(
        {
          'bg-purple-200 text-white': iseSelected,
          'text-blue-300': !iseSelected,
        },
        'text-small px-2.5 py-2 cursor-pointer rounded-full border border-blue-300',
        'whitespace-nowrap font-inter-600',
      )}
    >
      {text}
    </div>
  );
};
