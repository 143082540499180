import { FC, Fragment, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Button } from 'src/components/Button';
import { Info } from 'src/components/Info';
import { SquareButton } from 'src/components/SquareButton';
import { smartestGameQuestions } from 'src/config/MOCK_DATA/gameSmartest';
import { Container } from 'src/containers';
import cn from 'classnames';

import { ReactComponent as CloseInsideIcon } from '../../../../assets/icons/kit/close-inside.svg';
import { ReactComponent as CheckInsideIcon } from '../../../../assets/icons/kit/check-inside.svg';
import { ReactComponent as QuestionIcon } from '../../../../assets/icons/kit/question.svg';
import InfoBb7 from '../../../../assets/icons/info/info-bg-7.svg';

export const GameSmartestDesc: FC = () => {
  const { category } = useParams();
  const [answer, setAnswer] = useState<string>('');

  const question = useMemo(
    () => (category ? smartestGameQuestions[category] : undefined),
    [category],
  );

  return (
    <Container classNames="gap-8">
      {question ? (
        <>
          <Info
            title="Правда или ложь?"
            bg={InfoBb7}
            subtitle={question.question}
            rightBlock={<QuestionIcon className="ml-auto h-14 mr-4 w-fit fill-white relative" />}
          />
          <div className="flex">
            {[
              { type: 'truth', text: 'Правда' },
              { type: 'lie', text: 'Ложь' },
            ].map((el, i) => (
              <Fragment key={`smartest-game-button-${el.type}`}>
                <div
                  onClick={() => !answer && setAnswer(el.type)}
                  key={el.type}
                  className={cn(
                    {
                      'bg-white-50 border-y-2 border-violet-500 cursor-pointer': answer !== el.type,
                      'border-l-2': answer !== el.type && i === 0,
                      'border-r-2': answer !== el.type && i === 1,
                      'rounded-l-xl': i === 0,
                      'rounded-r-xl': i === 1,
                      'bg-purple-200 border-0': answer === el.type && el.type === 'lie',
                      'bg-yellow-200 border-0': answer === el.type && el.type === 'truth',
                    },
                    'flex w-1/2 items-center justify-center py-3',
                  )}
                >
                  {el.text}
                </div>
                {!i ? <div className="h-full w-0.5 bg-violet-500" /> : null}
              </Fragment>
            ))}
          </div>
          {answer ? (
            <div className="rounded-xl w-full flex pr-2.5 pl-3 py-4 border border-violet-400 bg-white gap-4">
              <SquareButton
                variant={answer === question.answer ? 'green' : 'red'}
                icon={answer === question.answer ? <CheckInsideIcon /> : <CloseInsideIcon />}
                className="ml-1 mt-1"
              />
              <div className="text-xs">
                <div
                  className={cn({
                    'text-red-200': answer !== question.answer,
                    'text-green-200': answer === question.answer,
                  })}
                >{`Ответ ${answer === question.answer ? 'верный' : 'неверный'}`}</div>
                <div>{question.description}</div>
              </div>
            </div>
          ) : null}
        </>
      ) : null}

      <Button
        className="rounded-lg h-12 mt-auto"
        text="Сыграть еще раз"
        variant="red"
        href="/games/smartest/categories/"
      />
    </Container>
  );
};
