import { FC } from 'react';
import { Button } from 'src/components/Button';
import { Container } from 'src/containers';

import { ReactComponent as TgIcon } from '../../../assets/icons/kit/tg.svg';

export const WineTinderVoteHome: FC = () => {
  return (
    <Container classNames="items-center gap-8">
      <img src="/static/images/wine-tinder-vote.svg" alt="" className="w-40 h-40 object-contain" />
      <div className="relative w-full rounded-xl bg-violet-600 p-3 overflow-hidden">
        <div className="absolute w-full square rounded-extra-large bg-yellow-200 -top-[180%] -left-[30%] rotate-45" />
        <div className="w-4/5 text-xs relative">
          Вы были приглашены для выбора вина для вечеринки с{' '}
          <div className="cursor-pointer text-purple-200 inline">@Viki</div>. Начинаем голосование,
          ставьте реакции
        </div>
      </div>

      <div className="flex mx-auto items-center gap-3">
        <div className="bg-blue-700 w-8 h-8 flex items-center justify-center rounded-full">
          <TgIcon className="fill-white w-4 h-4 -ml-0.5" />
        </div>
        <div className="w-fit text-xs">Поделиться в Telegram</div>
      </div>

      <Button
        href="/wine-tinder/vote/list/"
        text="Перейти к голосованию"
        variant="red"
        className="w-full h-12 rounded-lg"
      />
    </Container>
  );
};
