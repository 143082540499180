import { FC, useEffect, useRef, useState } from 'react';

import { ReactComponent as QuestionIcon } from '../../assets/icons/kit/question.svg';
import cn from 'classnames';

interface HowWorkProps {
  classNames?: string;
}

export const HowWork: FC<HowWorkProps> = ({ classNames }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  const ref = useRef<HTMLVideoElement>(null);

  const handleClick = () => {
    if (ref.current) {
      const video = ref.current;
      if (video.paused) {
        video.play().then();
      } else {
        video.pause();
      }
    }
  };

  useEffect(() => {
    if (isOpen && ref.current) {
      const menu = document.querySelector('#bottom-menu') as HTMLDivElement;

      setTimeout(() => {
        if (isOpen && menu) {
          setIsOpen(false);
          menu.classList.remove('translate-y-full');
        }
      }, 60000);
    }
  }, [isOpen]);

  const handleOpen = () => {
    const menu = document.querySelector('#bottom-menu') as HTMLDivElement;
    if (menu && !isOpen) {
      menu.classList.add('translate-y-full');
    }
    setIsOpen(true);
  };

  const onCanPLay = () => {
    if (ref.current) {
      const video = ref.current;
      video.muted = true;
      video.muted = false;
      setCanPlay(true);
      video.play().then();
    }
  };

  return (
    <>
      <div
        className={cn('flex items-center text-xs gap-2 cursor-pointer', classNames)}
        onClick={handleOpen}
      >
        <div className="bg-red-200 w-8 h-8 flex items-center justify-center p-2 rounded-full">
          <QuestionIcon className="fill-white" />
        </div>
        Как это работает?
      </div>
      {isOpen ? (
        <div className="w-screen h-screen fixed bg-blue-300 inset-0" onClick={handleClick}>
          {!canPlay ? (
            <img
              className="absolute w-full h-full object-contain inset-0"
              src="/video/preload.svg"
              alt=""
            />
          ) : null}
          <video
            poster="/video/preload.svg"
            ref={ref}
            className="w-full h-full object-contain"
            onCanPlay={onCanPLay}
            muted
            autoPlay
          >
            <source
              src="/video/mystery-box.mp4"
              type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
            />
          </video>
        </div>
      ) : null}
    </>
  );
};
