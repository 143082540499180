import { FC } from 'react';
import { Button } from 'src/components/Button';
import { Info } from 'src/components/Info';
import { Container } from 'src/containers';

import { ReactComponent as GoodbyeIcon } from '../../../assets/icons/goodbye.svg';
import InfoBg5 from '../../../assets/icons/info/info-bg-5.svg';

export const DeliverySuccess: FC = () => {
  return (
    <Container>
      <GoodbyeIcon className="w-44 h-44 mx-auto my-10" />

      <Info
        title="Поздравляем!"
        subtitle={`Ваш заказ будет доставлен\nВремя доставки с 9:00 до 12:00`}
        bg={InfoBg5}
        rightText="29.12"
      />

      <Button
        text="Продолжить покупки"
        variant="red"
        className="w-full rounded-xl h-12 mt-auto"
        href="/catalog/list/"
      />
    </Container>
  );
};
