import { FC } from 'react';
import { TabItemType } from 'src/config/types';
import cn from 'classnames';

interface TabsProps {
  values: TabItemType[];
  value: TabItemType;
  setValue: (arg: TabItemType) => void;
  classNames?: string;
}

export const Tabs: FC<TabsProps> = ({ values, value, setValue, classNames }) => {
  return (
    <div className={cn('w-full flex border border-blue-300 rounded-full', classNames)}>
      {values.map((el, i) => (
        <div
          onClick={() => setValue(el)}
          key={`mystery-box-${el.value}-text`}
          className={cn(
            {
              'text-white bg-purple-200 border-blue-300': el.value === value.value,
              'border-r': !i,
              'border-l': i,
              'text-blue-300': el.value !== value.value,
            },
            'cursor-pointer h-7 w-1/2 flex items-center justify-center font-inter-600 text-small rounded-full',
          )}
        >
          {el.name}
        </div>
      ))}
    </div>
  );
};
