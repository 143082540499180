import { FC } from 'react';
import { CouponType } from 'src/config/types';

import cn from 'classnames';

interface CouponProps {
  data: CouponType;
}

export const Coupon: FC<CouponProps> = ({
  data: {
    coupon,
    title,
    variant,
    description,
    image,
    discount,
    discountDescription,
    logo,
    stockAbout,
  },
}) => {
  const isYellow = variant === 'yellow';

  return (
    <div
      className={cn(
        {
          'bg-yellow-200': isYellow,
          'bg-red-200': variant === 'red',
          'bg-green-200': variant === 'green',
        },
        'p-6 w-full flex flex-col gap-y-5 rounded-2xl',
      )}
    >
      <div className="flex gap-x-3 items-center">
        <img src={logo} alt="" className="w-16 h-16 min-w-[4rem]" />
        <div
          className={cn(
            { 'text-white': !isYellow, 'text-red-200': isYellow },
            'font-inter-800 text-2xl',
          )}
        >
          {title}
        </div>
      </div>

      {description ? (
        <div className="bg-white rounded text-blue-300 text-xs p-1 text-center">{description}</div>
      ) : null}

      <div
        className={cn({ 'text-white': !isYellow, 'text-blue-300': isYellow }, 'text-center w-full')}
      >
        <div className={'w-full font-inter-800 text-3xl mb-2'}>{discount}</div>
        <div className={'w-full font-inter-500 text-xl'}>{discountDescription}</div>
      </div>

      {coupon ? (
        <div
          className={cn(
            {
              'text-green-200': !isYellow,
              'text-red-200': isYellow,
            },
            'bg-white w-full text-center rounded cursor-pointer font-inter-700 text-3xl p-1.5',
          )}
        >
          {coupon}
        </div>
      ) : null}

      {stockAbout ? (
        <div className="w-full text-center text-blue-300 text-xs">{stockAbout}</div>
      ) : null}

      <img src={image} alt="" className="w-full h-36 object-contain" />
    </div>
  );
};
