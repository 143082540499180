import { ConstructorItemVariants } from '../pages/Games/GameConstructor/GameConstructorDesc';
import { randomInteger } from './index';

/**
 * @description
 * create matrix
 * @param {number} rows number of rows
 * @param {number} cols number of columns
 * @returns {ConstructorItemVariants[][]} return the matrix.
 */
export const createConstructorMatrix = (
  rows: number,
  cols: number,
): ConstructorItemVariants[][] => {
  const matrix = new Array(rows).fill('').map(_ => new Array(cols).fill(''));

  ['V', 'V', 'B', 'B', 'P', 'P', 'Y', 'Y', 'R', 'R', 'G', 'G'].forEach(item => {
    const emptyCells = getEmptyCells(matrix);
    const coords = randomInteger(0, emptyCells.length - 1);
    matrix[emptyCells[coords][0]][emptyCells[coords][1]] = item;
  });

  return matrix;
};

export const placeConstructorItem = (
  matrix: ConstructorItemVariants[][],
  item: ConstructorItemVariants,
) => {
  const emptyCells = getEmptyCells(matrix);
  const coords = randomInteger(0, emptyCells.length - 1);
  matrix[emptyCells[coords][0]][emptyCells[coords][1]] = item;

  return {
    matrix,
    lastRow: emptyCells[coords][0],
    lastCol: emptyCells[coords][1],
  };
};

export const getEmptyCells = (matrix: ConstructorItemVariants[][]) => {
  const res: number[][] = [];
  matrix.forEach((_, r) =>
    _.forEach((_, c) => {
      if (matrix[r][c] === '') {
        res.push([r, c]);
      }
    }),
  );

  return res;
};
