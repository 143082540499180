import { FC } from 'react';
import { calendar } from 'src/config/MOCK_DATA/calendar';
import { AdventCalendarCard } from 'src/components/Cards/AdventCalendarCard';
import { Container } from 'src/containers';

export const AdventCalendar: FC = () => {
  return (
    <Container classNames="flex-none grid grid-cols-2 gap-4">
      {calendar.map(calendarItem => (
        <AdventCalendarCard key={`advent-calendar-item-${calendarItem.id}`} {...calendarItem} />
      ))}
    </Container>
  );
};
