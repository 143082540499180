import { AchievementsType } from '../types';
import cn from 'classnames';

const yellow200 = 'fill-yellow-200';
const blue300 = 'fill-blue-300';
const blue200 = 'fill-blue-200';
const red200 = 'fill-red-200';
const violet200 = 'fill-violet-200';
const purple200 = 'fill-purple-200';
const white = 'fill-white';
const r90 = 'rotate-90';
const r180 = 'rotate-180';
const r270 = 'rotate-270';

export const achievements: AchievementsType[] = [
  {
    name: 'Самый умный',
    currentBalls: 25,
    targetBalls: 25,
    bg: ['bg-violet-800', 'bg-purple-200'],
    blocks: [
      [
        {
          shape: 'triangle',
          steps: [{ style: r180 }, { style: yellow200 }, { style: cn(r180, yellow200) }],
        },
        {
          shape: 'triangle',
          steps: [{ style: r270 }, { style: yellow200 }, { style: cn(r270, yellow200) }],
        },
      ],
      [
        {
          shape: 'horn',
          steps: [{ style: '' }, { style: cn(r180, blue300) }, { style: blue300 }],
        },
        {
          shape: 'arch',
          steps: [
            { style: '', circleStyle: 'bg-white' },
            { style: cn(r270, white) },
            { style: white, circleStyle: 'bg-blue-300' },
          ],
        },
        {
          shape: 'arch',
          steps: [
            { style: '', circleStyle: 'bg-white' },
            { style: cn(r270, white) },
            { style: white, circleStyle: 'bg-blue-300' },
          ],
        },
        {
          shape: 'horn',
          steps: [{ style: r90 }, { style: cn(r180, blue200) }, { style: cn(r90, blue200) }],
        },
      ],
      [
        {
          shape: 'triangle',
          steps: [{ style: r180 }, { style: yellow200 }, { style: cn(r180, yellow200) }],
        },
        {
          shape: 'horn',
          steps: [{ style: r180 }, { style: cn(r90, red200) }, { style: cn(r180, red200) }],
        },
        {
          shape: 'horn',
          steps: [{ style: r270 }, { style: cn(r90, red200) }, { style: cn(r270, red200) }],
        },
        {
          shape: 'triangle',
          steps: [{ style: r270 }, { style: yellow200 }, { style: cn(r270, yellow200) }],
        },
      ],
      [
        {
          shape: 'circle',
          steps: [{ style: '' }, { style: blue300 }, { style: blue300 }],
        },
        {
          shape: 'circle',
          steps: [{ style: '' }, { style: violet200 }, { style: violet200 }],
        },
      ],
    ],
  },
  {
    name: 'Самый смелый',
    currentBalls: 60,
    targetBalls: 150,
    bg: ['bg-yellow-100', 'bg-yellow-200'],
    blocks: [
      [
        {
          shape: 'circle',
          steps: [
            { style: '', circleStyle: 'bg-white' },
            { style: purple200 },
            { style: purple200, circleStyle: 'bg-white' },
          ],
        },
        {
          shape: 'circle',
          steps: [
            { style: '', circleStyle: 'bg-white' },
            { style: red200 },
            { style: red200, circleStyle: 'bg-white' },
          ],
        },
      ],
      [
        {
          shape: 'horn',
          steps: [{ style: '' }, { style: cn(r180, red200) }, { style: red200 }],
        },
        {
          shape: 'triangle',
          steps: [{ style: r270 }, { style: blue300 }, { style: cn(r270, blue300) }],
        },
        {
          shape: 'triangle',
          steps: [{ style: r180 }, { style: blue300 }, { style: cn(r180, blue300) }],
        },
        {
          shape: 'horn',
          steps: [{ style: r90 }, { style: cn(r180, blue200) }, { style: cn(r90, blue200) }],
        },
      ],
      [
        {
          shape: 'triangle',
          steps: [{ style: r180 }, { style: violet200 }, { style: cn(r180, violet200) }],
        },
        { shape: 'circle', steps: [{ style: '' }, { style: purple200 }, { style: purple200 }] },
        { shape: 'square', steps: [{ style: '' }, { style: violet200 }, { style: violet200 }] },
        {
          shape: 'triangle',
          steps: [{ style: r270 }, { style: purple200 }, { style: cn(r270, purple200) }],
        },
      ],
      [
        { shape: 'horn', steps: [{ style: '' }, { style: cn(r180, blue200) }, { style: blue200 }] },
        { shape: 'horn', steps: [{ style: '' }, { style: cn(r180, red200) }, { style: red200 }] },
      ],
    ],
  },
  {
    name: 'Самый красивый',
    currentBalls: 120,
    targetBalls: 120,
    bg: ['bg-violet-1000', 'bg-white'],
    blocks: [
      [
        {
          shape: 'arch',
          steps: [{ style: r270 }, { style: cn(r270, red200) }, { style: cn(r270, red200) }],
        },
        {
          shape: 'horn',
          steps: [{ style: r90 }, { style: cn(r180, blue200) }, { style: cn(r90, blue200) }],
        },
        {
          shape: 'horn',
          steps: [{ style: '' }, { style: cn(r180, purple200) }, { style: purple200 }],
        },
        {
          shape: 'arch',
          steps: [{ style: 'r90' }, { style: cn(r270, red200) }, { style: cn(r90, red200) }],
        },
      ],
      [
        {
          shape: 'triangle',
          steps: [{ style: '' }, { style: yellow200 }, { style: yellow200 }],
        },
        {
          shape: 'circle',
          steps: [
            { style: '', circleStyle: 'bg-white' },
            { style: blue300 },
            {
              style: blue300,
              circleStyle: 'bg-white',
            },
          ],
        },
        {
          shape: 'circle',
          steps: [
            { style: '', circleStyle: 'bg-white' },
            { style: blue300 },
            {
              style: blue300,
              circleStyle: 'bg-white',
            },
          ],
        },
        {
          shape: 'triangle',
          steps: [{ style: r90 }, { style: yellow200 }, { style: cn(r90, yellow200) }],
        },
      ],
      [
        {
          shape: 'circle',
          steps: [{ style: '' }, { style: violet200 }, { style: violet200 }],
        },
        {
          shape: 'horn',
          steps: [{ style: r270 }, { style: cn(r180, red200) }, { style: cn(r270, red200) }],
        },
        {
          shape: 'horn',
          steps: [{ style: r180 }, { style: cn(r180, yellow200) }, { style: cn(r180, yellow200) }],
        },
        {
          shape: 'triangle',
          steps: [{ style: r270 }, { style: violet200 }, { style: cn(r270, violet200) }],
        },
      ],
      [
        {
          shape: 'circle',
          steps: [{ style: '' }, { style: purple200 }, { style: purple200 }],
        },
        { shape: 'circle', steps: [{ style: '' }, { style: red200 }, { style: red200 }] },
      ],
    ],
  },
  {
    name: 'Самый любознательный',
    currentBalls: 400,
    targetBalls: 400,
    bg: ['bg-violet-1100', 'bg-blue-300'],
    blocks: [
      [
        {
          shape: 'smallCircle',
          steps: [
            { style: 'bg-white ml-auto' },
            { style: 'opacity-0 ml-auto' },
            { style: 'bg-white ml-auto' },
          ],
        },
        {
          shape: 'arch',
          steps: [{ style: r270 }, { style: cn(r270, violet200) }, { style: cn(r270, violet200) }],
        },
        {
          shape: 'arch',
          steps: [{ style: r90 }, { style: cn(r270, purple200) }, { style: cn(r90, purple200) }],
        },
        {
          shape: 'smallCircle',
          steps: [{ style: 'bg-white' }, { style: 'opacity-0' }, { style: 'bg-white' }],
        },
      ],
      [
        { shape: 'horn', steps: [{ style: '' }, { style: cn(r180, blue200) }, { style: blue200 }] },
        {
          shape: 'triangle',
          steps: [{ style: r270 }, { style: yellow200 }, { style: cn(r270, yellow200) }],
        },
        {
          shape: 'triangle',
          steps: [{ style: r180 }, { style: red200 }, { style: cn(r180, red200) }],
        },
        {
          shape: 'horn',
          steps: [{ style: r90 }, { style: cn(r180, yellow200) }, { style: cn(r90, yellow200) }],
        },
      ],
      [
        {
          shape: 'triangle',
          steps: [{ style: r180 }, { style: purple200 }, { style: cn(r180, purple200) }],
        },
        { shape: 'square', steps: [{ style: '' }, { style: yellow200 }, { style: yellow200 }] },
        { shape: 'square', steps: [{ style: '' }, { style: yellow200 }, { style: yellow200 }] },
        {
          shape: 'triangle',
          steps: [{ style: r270 }, { style: blue200 }, { style: cn(r270, blue200) }],
        },
      ],
      [
        {
          shape: 'arch',
          steps: [{ style: r180 }, { style: cn(r270, violet200) }, { style: cn(r180, violet200) }],
        },
        {
          shape: 'arch',
          steps: [{ style: r180 }, { style: cn(r270, red200) }, { style: cn(r180, red200) }],
        },
      ],
    ],
  },
  {
    name: 'Самый быстрый',
    currentBalls: 340,
    targetBalls: 340,
    bg: ['bg-blue-600', 'bg-blue-400'],
    blocks: [
      [
        { shape: 'horn', steps: [{ style: '' }, { style: white }, { style: white }] },
        {
          shape: 'circle',
          steps: [
            { style: '', circleStyle: 'bg-white' },
            { style: blue300 },
            { style: blue300, circleStyle: white },
          ],
        },
        {
          shape: 'horn',
          steps: [{ style: r90 }, { style: cn(r90, yellow200) }, { style: cn(r90, yellow200) }],
        },
      ],
      [{ shape: 'circle', steps: [{ style: '' }, { style: violet200 }, { style: violet200 }] }],
      [
        { shape: 'horn', steps: [{ style: '' }, { style: violet200 }, { style: violet200 }] },
        { shape: 'square', steps: [{ style: '' }, { style: yellow200 }, { style: yellow200 }] },
        {
          shape: 'arch',
          steps: [{ style: r90 }, { style: cn(r90, white) }, { style: cn(r90, white) }],
        },
      ],
      [
        { shape: 'circle', steps: [{ style: '' }, { style: red200 }, { style: red200 }] },
        {
          shape: 'triangle',
          steps: [{ style: r90 }, { style: cn(r90, yellow200) }, { style: cn(r90, yellow200) }],
        },
        { shape: 'triangle', steps: [{ style: '' }, { style: blue300 }, { style: blue300 }] },
        { shape: 'circle', steps: [{ style: '' }, { style: purple200 }, { style: purple200 }] },
      ],
    ],
  },
  {
    name: 'Самый смирный',
    currentBalls: 15,
    targetBalls: 15,
    bg: ['bg-violet-800', 'bg-purple-200'],
    blocks: [
      [
        { shape: 'horn', steps: [{ style: '' }, { style: blue200 }, { style: blue200 }] },
        {
          shape: 'horn',
          steps: [{ style: r90 }, { style: cn(r90, purple200) }, { style: cn(r90, white) }],
        },
      ],
      [
        {
          shape: 'circle',
          steps: [
            { style: '', circleStyle: 'bg-white' },
            { style: red200 },
            {
              style: blue300,
              circleStyle: 'bg-white',
            },
          ],
        },
        {
          shape: 'circle',
          steps: [
            { style: '', circleStyle: 'bg-white' },
            { style: red200 },
            {
              style: blue300,
              circleStyle: 'bg-white',
            },
          ],
        },
      ],
      [
        {
          shape: 'arch',
          steps: [{ style: r270 }, { style: cn(r270, purple200) }, { style: cn(r270, violet200) }],
        },
        { shape: 'square', steps: [{ style: '' }, { style: blue300 }, { style: red200 }] },
        { shape: 'square', steps: [{ style: '' }, { style: blue200 }, { style: blue200 }] },
        {
          shape: 'arch',
          steps: [{ style: r90 }, { style: cn(r90, purple200) }, { style: cn(r90, violet200) }],
        },
      ],
      [
        {
          shape: 'arch',
          steps: [{ style: r180 }, { style: cn(r180, red200) }, { style: cn(r180, white) }],
        },
        {
          shape: 'arch',
          steps: [{ style: r180 }, { style: cn(r180, yellow200) }, { style: cn(r180, yellow200) }],
        },
      ],
    ],
  },
];
