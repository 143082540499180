import { FC, useState } from 'react';
import { Input } from 'src/components/Input';
import { AppLink } from 'src/components/AppLink';
import { coupons } from 'src/config/MOCK_DATA/coupons';
import { Coupon } from 'src/components/Cards/Coupon';
import { Container } from 'src/containers';
import { sort } from 'src/config/MOCK_DATA/kit';
import { Select, SelectedItemType } from 'src/components/Select';

import { ReactComponent as FilterIcon } from '../../../assets/icons/kit/filter.svg';
import { ReactComponent as LoupIcon } from '../../../assets/icons/kit/loup.svg';

export const CouponsList: FC = () => {
  const [selectedSort, setSelectedSort] = useState<SelectedItemType | undefined>(sort[0]);

  return (
    <Container classNames="gap-4">
      <Input
        placeHolder="Поиск"
        className="h-11 min-h-[2.75rem]"
        icon={<LoupIcon className="fill-blue-300 w-3.5 h-3.5" />}
      />

      <div className="flex items-center">
        <div className="text-sm mr-2.5 font-inter-500">Сортировать по:</div>
        <Select
          list={sort}
          selected={selectedSort}
          onClick={setSelectedSort}
          classNames="w-36"
          height="h-7"
        />

        <AppLink
          href="/coupons/filter/"
          className="ml-auto bg-blue-300 rounded-full h-6 w-6 flex items-center justify-center"
        >
          <FilterIcon className="fill-white w-3 h-3" />
        </AppLink>
      </div>

      <div className="flex flex-col gap-5">
        {coupons.map(coupon => (
          <Coupon key={`coupon-${coupon.title}`} data={coupon} />
        ))}
      </div>
    </Container>
  );
};
