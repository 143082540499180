import { FC } from 'react';
import { Info } from 'src/components/Info';
import { Container } from 'src/containers';

import { ReactComponent as CarImage } from '../../assets/icons/info/car.svg';
import InfoBg1 from '../../assets/icons/info/info-bg-1.svg';
import InfoBg2 from '../../assets/icons/info/info-bg-2.svg';
import InfoBg3 from '../../assets/icons/info/info-bg-3.svg';

export const Account: FC = () => {
  return (
    <Container classNames="gap-y-6">
      <Info title={`Твои шансы для участия\nв розыгрыше недели`} bg={InfoBg1} rightText="10" />
      <Info title={`Всего шансов для\nучастия в розыгрыше`} bg={InfoBg2} rightText="50" />
      <Info
        title={`Твой выигрыш:\nавтомобиль`}
        bg={InfoBg3}
        rightBlock={<CarImage className="w-32 h-full absolute right-3 top-0" />}
      />
    </Container>
  );
};
