import { FC } from 'react';
import { Button } from '../../Button';
import { CatalogItemType } from 'src/config/types';

interface MysteryBoxCardProps {
  data: CatalogItemType;
}

export const MysteryBoxCard: FC<MysteryBoxCardProps> = ({ data }) => {
  return (
    <div className="w-full bg-white p-2 rounded-xl flex flex-col gap-4">
      <div className="w-full h-36 rounded-md overflow-hidden relative">
        <img src={data.img} alt="" className="w-full h-full" />
        <div
          className={
            'bg-yellow-200 rounded-tl-md p-1 text-small font-inter-700 ' +
            'absolute right-0 bottom-0'
          }
        >
          {data.discount}
        </div>
      </div>
      <div className="font-inter-500">{data.name}</div>
      <div className="flex gap-2 items-center">
        <div className="text-violet-900 text-lg line-through font-inter-500">{data.oldPrice}</div>
        <div className="text-red-200 font-inter-700 text-xl">{data.currentPrice}</div>
      </div>

      <Button
        onClick={() => null}
        text="Показать состав набора"
        variant="redOutline"
        className="w-full h-12 rounded-xl"
      />

      <Button
        href="/catalog/basket/mystery-box/"
        text="Выбрать"
        variant="red"
        className="w-full h-12 rounded-xl"
      />
    </div>
  );
};
