import { FC, useState } from 'react';
import { Button } from 'src/components/Button';
import { Select, SelectedItemType } from 'src/components/Select';
import { FilterLine } from 'src/components/FilterLine';
import { Categories } from 'src/components/Categories';
import { Interval } from 'src/config/types';
import { MultiRangeSlider } from 'src/components/MultiRangeSlider';
import cn from 'classnames';

export const OnlineConsultantHome: FC = () => {
  const colorIconStyles = 'w-3.5 h-3.5 rounded';

  const categories = ['Шар', 'Квадрат', 'Треугольник', 'Овал'];
  const sizes = ['Маленький', 'Средний', 'Большой'];

  const colorsList: SelectedItemType[] = [
    { text: 'розовый', icon: <div className={cn(colorIconStyles, 'bg-pink-100')} /> },
    { text: 'фиолетовый', icon: <div className={cn(colorIconStyles, 'bg-violet-600')} /> },
    { text: 'жёлтый', icon: <div className={cn(colorIconStyles, 'bg-yellow-200')} /> },
  ];

  const [selectedCategory, setSelectedCategory] = useState([categories[0]]);
  const [selectedSize, setSelectedSize] = useState([sizes[0]]);
  const [selectedColor, setSelectedColor] = useState<SelectedItemType | undefined>();
  const [price, setPrice] = useState<Interval>({ min: 1200, max: 5000 });

  const handleChangePrice = (price: Interval) => {
    setPrice(price);
  };

  return (
    <div className="py-5 flex flex-col h-full gap-4">
      <FilterLine text="Категория товара" textClassNames="px-6">
        <Categories
          uniqKey="consultant-category"
          list={categories}
          selected={selectedCategory}
          setSelected={setSelectedCategory}
          rows={1}
          classNames="px-6"
        />
      </FilterLine>

      <FilterLine text="Цвет товара" textClassNames="px-6">
        <Select
          list={colorsList}
          selected={selectedColor}
          placeholder="Выберите цвет..."
          onClick={setSelectedColor}
          classNames="mx-6"
        />
      </FilterLine>

      <FilterLine text="Размер товара" textClassNames="px-6">
        <Categories
          uniqKey="consultant-size"
          list={sizes}
          selected={selectedSize}
          setSelected={setSelectedSize}
          rows={1}
          classNames="px-6"
        />
      </FilterLine>

      <FilterLine text="Ценовой диапозон" textClassNames="px-6">
        <MultiRangeSlider
          values={price}
          initValues={{ min: 1000, max: 9000 }}
          onChange={handleChangePrice}
          classNames="mx-6"
          valueType=" ₽"
        />
      </FilterLine>

      <div className="px-6 w-full mt-auto">
        <Button
          href="/online-consultant/list/"
          text="Применить"
          variant="red"
          className="w-full h-12 rounded-xl"
        />
      </div>
    </div>
  );
};
