import { CommonProps } from './types';
import { createSlice } from '@reduxjs/toolkit';

const initialState: CommonProps = {
  routeChangeTrigger: false,
};

const commonSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setRouteChangeTrigger: state => {
      state.routeChangeTrigger = !state.routeChangeTrigger;
    },
  },
});

export const { setRouteChangeTrigger } = commonSlice.actions;

const reducer = commonSlice.reducer;
export default reducer;
