import { FC, ReactNode } from 'react';

export interface InfoProps {
  title: string;
  subtitle?: string;
  rightText?: string;
  rightBlock?: ReactNode;
  bg: string;
}

export const Info: FC<InfoProps> = ({ title, subtitle, rightBlock, rightText, bg }) => {
  return (
    <div
      className={
        'p-4 relative rounded-xl min-h-[5rem] flex items-center justify-between ' +
        'whitespace-pre-wrap overflow-hidden'
      }
    >
      <img className="absolute w-full h-full left-0 top-0 object-cover" src={bg} alt="" />

      <div className="relative flex flex-col gap-y-2">
        <div className="text-blue-300 font-inter-600 text-xs">{title}</div>
        {subtitle ? <div className="text-black-100 text-extra-medium">{subtitle}</div> : null}
      </div>
      {rightText ? (
        <div className="font-inter-700 text-white text-3xl relative">{rightText}</div>
      ) : null}
      {rightBlock ? rightBlock : null}
    </div>
  );
};
