import { FC, useMemo, useState } from 'react';
import { Input } from 'src/components/Input';
import { ProductBig } from 'src/components/Cards/ProductBig';
import { AppLink } from 'src/components/AppLink';
import { Select, SelectedItemType } from 'src/components/Select';
import { sort } from 'src/config/MOCK_DATA/kit';
import { Container } from 'src/containers';
import { useParams } from 'react-router';
import { useAppSelector } from 'src/hooks/redux';
import { CatalogItemType } from 'src/config/types';

import { ReactComponent as LoupIcon } from '../../../assets/icons/kit/loup.svg';
import { ReactComponent as FilterIcon } from '../../../assets/icons/kit/filter.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/kit/settings.svg';

export const CatalogList: FC = () => {
  const { id } = useParams();
  const { products } = useAppSelector(state => state.products);

  const [selectedSort, setSelectedSort] = useState<SelectedItemType>(sort[0]);

  const isWineCatalog = id === 'wine';

  const setSort = (sort: SelectedItemType) => {
    setSelectedSort(prev => {
      if (prev.text !== sort.text) {
        return sort;
      }

      return { ...sort, type: sort.type === 'ASC' ? 'DESC' : 'ASC' };
    });
  };

  const sortList = (arr: CatalogItemType[]) => {
    if (!arr || !arr.length) return [];

    if (selectedSort.text === 'Цене') {
      const sortedList = [...arr].sort((a, b) => a.currentPrice - b.currentPrice);
      return selectedSort.type === 'ASC' ? sortedList : sortedList.reverse();
    }

    if (selectedSort.text === 'Скидке') {
      const sortedList = [...arr].sort((a, b) => (a.discount || 0) - (b.discount || 0));
      return selectedSort.type === 'ASC' ? sortedList : sortedList.reverse();
    }
  };

  const data = useMemo(() => (id ? sortList(products?.[id]) : []), [id, products, selectedSort]);

  return (
    <Container>
      <Input
        placeHolder="Поиск"
        className="min-h-[2.75rem] h-11 mb-4"
        icon={<LoupIcon className="fill-blue-300 w-3.5 h-3.5" />}
      />
      <div className="flex items-center mb-4">
        <div className="text-sm mr-2.5 font-inter-500">Сортировать по:</div>
        <Select
          list={sort}
          selected={selectedSort}
          onClick={setSort}
          classNames="w-36"
          height="h-7"
        />
        <AppLink
          href={isWineCatalog ? '/wine-tinder/filter/' : '/catalog/filter/'}
          className="ml-auto bg-blue-300 rounded-full h-6 w-6 flex items-center justify-center"
        >
          {isWineCatalog ? (
            <SettingsIcon className="fill-white w-3 h-3" />
          ) : (
            <FilterIcon className="fill-white w-3 h-3" />
          )}
        </AppLink>
      </div>
      <div className="grid grid-cols-2 gap-4">
        {data &&
          data.map((item, i) => (
            <ProductBig
              data={item}
              key={`product-big-${i}`}
              imgClassNames={isWineCatalog ? 'object-contain' : ''}
            />
          ))}
      </div>
    </Container>
  );
};
