import { FC, useMemo, useRef, useState } from 'react';
import { Sticker } from 'src/components/Cards/Sticker';
import { useParams } from 'react-router';
import { stickers } from 'src/config/MOCK_DATA/stickers';
import { Info } from 'src/components/Info';
import { Button } from 'src/components/Button';
import { useOuterClick } from 'src/hooks/useOuterClick';
import cn from 'classnames';

import InfoBg9 from '../../../assets/icons/info/info-bg-9.svg';

const cols = 8;
const rows = 6;

export const StickersDesc: FC = () => {
  const { stickerId } = useParams();
  const ref = useRef<HTMLDivElement>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  useOuterClick({ ref, clickOutSide: handleCloseModal });

  const sticker = stickers.find(sticker => sticker.id.toString() === stickerId);

  const checkSticker = (r: number, c: number) => r * cols + c < (sticker?.stickersCount || 0);

  const sortedPrizes = sticker?.prizes.sort((a, b) => a.count - b.count) || [];
  const countBeforeFirstPrize = sortedPrizes[0].count - (sticker?.stickersCount || 0);
  const curActivePrizes = sortedPrizes?.filter(
    prize => prize.count - (sticker?.stickersCount || 0) < 0,
  );
  const prizesInString = useMemo(() => {
    if (sortedPrizes) {
      return sortedPrizes.map(prize => `${prize.text} - ${prize.count} наклеек`).join('\n');
    }
  }, [sortedPrizes]);

  return sticker ? (
    <div
      className={cn({ 'overflow-auto': !openModal }, 'py-5 px-6 flex flex-col gap-y-5 relative')}
    >
      <Sticker desc data={sticker} />
      <div>
        {Array.from({ length: rows }).map((_, r) => (
          <div key={`sticker-row-${r}`} className="flex">
            {Array.from({ length: cols }).map((_, c) => (
              <div
                key={`sticker-row-${r}-col-${c}`}
                className={cn(
                  {
                    'bg-white-50 border-violet-200': !checkSticker(r, c),
                    'bg-violet-200 border-white': checkSticker(r, c),
                  },
                  'w-full square rounded p-1 border',
                )}
              >
                {checkSticker(r, c) ? <img src="/static/images/seven.svg" alt="" /> : null}
              </div>
            ))}
          </div>
        ))}
      </div>
      {countBeforeFirstPrize > 0 ? (
        <Info
          bg={InfoBg9}
          title={`Недостаточно наклеек.\nЧтобы получить приз\nНужно собрать еще`}
          rightText={countBeforeFirstPrize.toString()}
        />
      ) : (
        <div className="flex gap-x-3">
          <Button
            onClick={handleOpenModal}
            text="Получить подарок"
            variant="red"
            className="h-12 rounded-xl w-full text-sm"
          />
          <Button
            href="/stickers/list/"
            text="Собирать дальше"
            variant="redOutline"
            className="h-12 rounded-xl w-full text-sm"
          />
        </div>
      )}

      {openModal ? (
        <div className="w-full h-full top-0 left-0 bg-violet-80 absolute z-30 flex items-center p-6">
          <div
            ref={ref}
            className="w-full bg-yellow-200 rounded-xl px-4 py-6 flex flex-col gap-y-5"
          >
            <div className="flex gap-x-2 items-center text-blue-300 whitespace-pre-wrap">
              <div className="flex flex-col gap-y-1">
                <div className="text-xs font-inter-600">{`Поздравляем!\nВаш приз: ${
                  curActivePrizes?.[curActivePrizes?.length - 1].text
                }.`}</div>
                <div className="text-small">{prizesInString}</div>
              </div>
              <img alt="" src="/static/images/bokal.svg" className="w-2/5" />
            </div>

            <div className="flex flex-col gap-y-2">
              <Button
                text="Получить подарок"
                variant="red"
                className="h-12 rounded-xl w-full text-sm"
              />
              <Button
                href="/stickers/list/"
                text="Собирать дальше"
                variant="redOutline"
                className="h-12 rounded-xl w-full text-sm bg-transparent"
              />
            </div>

            <div className="text-extra-medium text-blue-300">
              *Для того чтобы получить сковородку соберите еще 10 наклеек
            </div>
          </div>
        </div>
      ) : null}
    </div>
  ) : null;
};
