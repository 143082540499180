import { FC, useState } from 'react';
import { DeliveryItem } from 'src/components/DeliveryItem';
import { Button } from 'src/components/Button';
import { Info } from 'src/components/Info';
import { Container } from 'src/containers';
import { FilterLine } from 'src/components/FilterLine';

import { ReactComponent as HandsIcon } from 'src/assets/icons/info/hands.svg';
import InfoBg4 from 'src/assets/icons/info/info-bg-4.svg';
import cn from 'classnames';

export const DeliveryAddress: FC = () => {
  const [selected, setSelected] = useState(1);

  const data: {
    text: { lg: string; md: string; sm: string };
    deliveryVariant: 'pickup' | 'courier';
  }[] = [
    {
      text: {
        lg: 'ул. Розы Люксембкрг, 303, оф. 35',
        md: 'г. Таганрог, Ростовская обл.',
        sm: 'Самовывоз',
      },
      deliveryVariant: 'pickup',
    },
    {
      text: {
        lg: 'ул. Петровская, 85, кв. 2',
        md: 'г. Таганрог, Ростовская обл.',
        sm: 'Курьерская доставка',
      },
      deliveryVariant: 'courier',
    },
    {
      text: {
        lg: 'ул. Греческая, 111, кв. 9',
        md: 'г. Таганрог, Ростовская обл.',
        sm: 'Курьерская доставка',
      },
      deliveryVariant: 'courier',
    },
  ];

  return (
    <Container classNames="gap-4">
      <FilterLine text="Выберите адрес доставки" />

      <Info
        title="Попробуйте самовывоз"
        subtitle={`Нет ограничения по времени\nЗабираете заказ в удобное время`}
        bg={InfoBg4}
        rightBlock={<HandsIcon className="h-full object-contain absolute right-0 top-0" />}
      />

      <div className="flex flex-col gap-2">
        <DeliveryItem text={{ lg: 'Новый адрес' }} />
        {data.map((el, i) => (
          <DeliveryItem
            onClick={() => setSelected(i)}
            key={`delivery-item-${i}`}
            deliveryVariant={el.deliveryVariant}
            text={el.text}
            className={cn({ 'bg-white': selected === i })}
          />
        ))}
      </div>

      <Button
        href="/delivery/type/"
        text="Далее"
        variant="red"
        className="w-full h-12 min-h-[3rem] rounded-xl mt-auto"
      />
    </Container>
  );
};
