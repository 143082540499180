import { FC, useMemo, useState } from 'react';
import { AchievementBlockType, AchievementsType } from 'src/config/types';
import { Button } from '../../Button';
import cn from 'classnames';

import { ReactComponent as TriangleIcon } from '../../../assets/icons/kit/triangle.svg';
import { ReactComponent as CircleIcon } from '../../../assets/icons/kit/circle.svg';
import { ReactComponent as SquareIcon } from '../../../assets/icons/kit/square.svg';
import { ReactComponent as ArchIcon } from '../../../assets/icons/kit/arch.svg';
import { ReactComponent as HornIcon } from '../../../assets/icons/kit/horn.svg';

export const AchievementCard: FC<AchievementsType> = ({
  name,
  blocks,
  bg,
  targetBalls,
  currentBalls,
}) => {
  const [step, setStep] = useState<0 | 1 | 2>(0);
  const [buttonType, setButtonType] = useState(targetBalls === currentBalls ? 1 : 0);

  const percents = useMemo(() => 100 * (currentBalls / targetBalls), [targetBalls, currentBalls]);

  const handleClick = () => {
    if (buttonType) {
      setButtonType(0);
      setStep(1);
      setTimeout(() => setStep(2), 500);
    }
  };

  const getIcon = (data: AchievementBlockType) => {
    const { shape, steps } = data;
    const style = `${steps[step].style} transition-all duration-500 p-[1px]`;
    const rootFill = 'fill-violet-900';

    if (!shape) {
      return <div />;
    }

    if (shape === 'smallCircle') {
      return <div className={cn(style, 'w-2.5 h-2.5 rounded-full')} />;
    }

    if (shape === 'triangle') {
      return <TriangleIcon className={cn(style, !step ? rootFill : '')} />;
    }

    if (shape === 'circle') {
      return <CircleIcon className={cn(style, !step ? rootFill : '')} />;
    }

    if (shape === 'square') {
      return <SquareIcon className={cn(style, !step ? rootFill : '')} />;
    }

    if (shape === 'arch') {
      return <ArchIcon className={cn(style, !step ? rootFill : '')} />;
    }

    if (shape === 'horn') {
      return <HornIcon className={cn(style, !step ? rootFill : '')} />;
    }
  };

  return (
    <div className="w-full gap-y-2 flex flex-col">
      <div
        className={cn(
          { 'bg-violet-700': !step },
          'w-full square p-0.5 rounded-2xl flex flex-col transition-all duration-500 overflow-hidden',
          step ? bg[step - 1] : '',
        )}
      >
        {blocks.map((row, r) => (
          <div key={`achievement-row-${r}`} className="flex justify-center">
            {row.map((col, c) => (
              <div key={`achievement-col-${r}-${c}`} className="w-1/4 square relative">
                {getIcon(col)}
                {col.steps[step].circleStyle ? (
                  <div
                    className={cn(col.steps[step].circleStyle, 'w-2.5 h-2.5 rounded-full absolute')}
                    style={{ left: 'calc(50% - 5px)', top: 'calc(50% - 5px)' }}
                  />
                ) : null}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="font-inter-500">{name}</div>
      <div className="flex justify-between items-center mt-auto">
        <div>
          <div className="mb-1 text-extra-medium">Ваши баллы:</div>
          <div className="flex gap-x-1">
            <div className="relative w-3 h-3 rounded-full overflow-hidden">
              <div className="w-full h-full absolute inset-0 bg-blue-300" />
              <div
                className="w-full h-full absolute inset-0"
                style={{ background: `conic-gradient(#FFD400 calc(${percents}%),#0000 0)` }}
              />
            </div>
            <div className="text-xs font-inter-600">{`${currentBalls}/${targetBalls}`}</div>
          </div>
        </div>
        <Button
          text="Собрать"
          onClick={handleClick}
          variant={buttonType ? 'redOutline' : 'violetLightOutline'}
          className={cn(
            {
              'text-red-200': !!buttonType,
              'text-violet-200': !buttonType,
            },
            'w-1/2 h-7 rounded-lg text-small',
          )}
        />
      </div>
    </div>
  );
};
