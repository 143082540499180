import { FC, useMemo, useState } from 'react';
import { achievements } from 'src/config/MOCK_DATA/achievements';
import { AchievementCard } from 'src/components/Cards/AchievementCard';
import { Container } from 'src/containers';
import { TabItemType } from 'src/config/types';
import { Tabs } from 'src/components/Tabs';

export const Achievements: FC = () => {
  const achievementTypes: TabItemType[] = [
    { value: 'all', name: 'Все награды' },
    { value: 'completed', name: 'Полученные' },
  ];

  const [selectedAchievementType, setSelectedAchievementType] = useState<TabItemType>(
    achievementTypes[0],
  );

  const curAchievements = useMemo(() => {
    if (selectedAchievementType.value === 'completed') {
      return achievements.filter(
        achievement => achievement.targetBalls === achievement.currentBalls,
      );
    }

    return achievements;
  }, [achievements, selectedAchievementType]);

  return (
    <Container classNames="gap-4">
      <Tabs
        values={achievementTypes}
        value={selectedAchievementType}
        setValue={setSelectedAchievementType}
      />

      <div className="grid grid-cols-2 gap-x-3 gap-y-6">
        {curAchievements.map(achievement => (
          <AchievementCard key={`achievement-${achievement.name}`} {...achievement} />
        ))}
      </div>
    </Container>
  );
};
