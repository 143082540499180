import { FC, useMemo } from 'react';
import { SquareButton, SquareButtonProps } from '../SquareButton';
import { useRoute } from 'src/hooks/useRoute';

import { ReactComponent as ArrowIcon } from '../../assets/icons/kit/arrow.svg';
import { ReactComponent as ShopIcon } from '../../assets/icons/kit/shop.svg';
import { ReactComponent as HumanIcon } from '../../assets/icons/kit/human.svg';
import { ReactComponent as CouponIcon } from '../../assets/icons/kit/coupon.svg';
import { ReactComponent as TetrisIcon } from '../../assets/icons/kit/tetris.svg';
import { ReactComponent as StickerIcon } from '../../assets/icons/kit/sticker.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/kit/calendar.svg';
import { ReactComponent as MedalIcon } from '../../assets/icons/kit/medal.svg';
import { ReactComponent as PresentIcon } from '../../assets/icons/kit/present.svg';
import { ReactComponent as OperatorIcon } from '../../assets/icons/kit/operator.svg';
import { ReactComponent as WineIcon } from '../../assets/icons/kit/wine.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/kit/settings.svg';
import { ReactComponent as BottleIcon } from '../../assets/icons/kit/bottle.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/kit/star.svg';

export interface HeadMenuProps {
  variant:
    | 'basket'
    | 'account'
    | 'games'
    | 'coupon'
    | 'sticker'
    | 'calendar'
    | 'achievements'
    | 'mysteryBox'
    | 'operator'
    | 'wine'
    | 'settings'
    | 'bottle'
    | 'star';
  text: string;
}

export const HeadMenu: FC<HeadMenuProps> = ({ variant, text }) => {
  const changeRoute = useRoute();

  const hrefInArr = window.location.href.split('/');

  const variants: { [key: string]: SquareButtonProps } = useMemo(
    () => ({
      basket: {
        href: `/catalog/basket/${hrefInArr[hrefInArr.length - 2] || ''}/`,
        variant: 'yellow',
        icon: <ShopIcon />,
        counter: true,
      },
      account: {
        href: '/account/',
        variant: 'yellow',
        icon: <HumanIcon />,
      },
      games: {
        href: '/games/home/',
        variant: 'yellow',
        icon: <TetrisIcon />,
      },
      coupon: {
        href: '/coupons/list/',
        variant: 'yellow',
        icon: <CouponIcon />,
      },
      sticker: {
        href: '/stickers/list/',
        variant: 'green',
        icon: <StickerIcon />,
      },
      calendar: {
        href: '/advent-calendar/',
        variant: 'red',
        icon: <CalendarIcon />,
      },
      achievements: {
        href: '/achievements/',
        variant: 'blue',
        icon: <MedalIcon />,
      },
      mysteryBox: {
        href: '/mystery-box/for-who/',
        variant: 'purple',
        icon: <PresentIcon />,
      },
      operator: {
        href: '/online-consultant/home/',
        variant: 'blue',
        icon: <OperatorIcon />,
      },
      wine: {
        href: '/wine-tinder/home/',
        variant: 'red',
        icon: <WineIcon />,
      },
      settings: {
        href: '/wine-tinder/filter/',
        variant: 'red',
        icon: <SettingsIcon />,
      },
      bottle: {
        href: '/wine-tinder/list/',
        variant: 'red',
        icon: <BottleIcon />,
      },
      star: {
        href: '/wine-tinder/vote/home/',
        variant: 'yellow',
        icon: <StarIcon />,
      },
    }),
    [hrefInArr],
  );

  const handleBackClick = () => {
    changeRoute();
  };
  return (
    <div className="flex px-6 items-center justify-between h-[4.5rem] min-h-[4.5rem] bg-violet-200 relative">
      <SquareButton
        variant="white"
        icon={<ArrowIcon className="-ml-0.5" />}
        stroke
        onClick={handleBackClick}
      />
      <div className="font-inter-600">{text}</div>
      <SquareButton {...variants[variant]} />
    </div>
  );
};
