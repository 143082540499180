import { CouponType } from '../types';

export const coupons: CouponType[] = [
  {
    title: 'Семейная супер скидка',
    logo: '/static/images/seven.svg',
    discount: 'скидка 20%',
    discountDescription: '+бесплатная доставка для новых пользователей промокоду',
    image: '/static/images/coupon-3.svg',
    coupon: 'SUP2023',
    variant: 'yellow',
  },
  {
    title: 'Доставка',
    logo: '/static/images/seven.svg',
    description:
      'Краснодар, только с 16 февраля по 1 марта при заказе от 2 000 ₽ в телеграмм-боте «Семерочка» в дополнение к вашей покупке будет приятный подарок!',
    discount: '+ 10 % скидка ',
    discountDescription: 'на первый заказ по промокоду',
    image: '/static/images/coupon-1.svg',
    coupon: 'ASD4598',
    variant: 'red',
  },
  {
    title: 'Купон на скидку',
    logo: '/static/images/seven.svg',
    description:
      ' С 5 февраля по 5 марта при покупке на сумму 777 ₽ в телеграмм-боте «Семерочка» получайте подарок!',
    discount: '+ 1000 баллов',
    discountDescription: 'на карту покупателя',
    stockAbout:
      'Акция распространяется на новых пользователей и тех, кто совершал заказы ранее. Сроки акции ограничены.',
    image: '/static/images/coupon-2.svg',
    variant: 'green',
  },
];

export const couponCategories = [
  'Все акции',
  'Красота и здоровье',
  'Продукты',
  'Спорт и отдых',
  'Путешествия',
  'Товары для дома',
  'SPA',
  'Обучение',
  'Другое',
];
