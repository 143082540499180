import { FC, useMemo, useRef, useState } from 'react';
import { SquareButton } from '../SquareButton';
import { mainMenu } from '../../config/data';
import { MainMenuItem } from '../../config/types';
import { Button } from '../Button';
import { useOuterClick } from '../../hooks/useOuterClick';
import { useRoute } from '../../hooks/useRoute';
import { useLocation } from 'react-router-dom';

import cn from 'classnames';

export const Menu: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const changeRoute = useRoute();
  const minSwipeDistance = 50;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const clickOutSide = () => {
    setIsOpen(false);
  };

  const isHomePage = useMemo(() => pathname === '/', [pathname]);

  useOuterClick({ ref, clickOutSide });

  const onTouchStart = (e: any) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientY);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isTopSwipe = distance > minSwipeDistance;
    const isBottomSwipe = distance < -minSwipeDistance;

    if (isOpen && isBottomSwipe) {
      setIsOpen(false);
    }

    if (!isOpen && isTopSwipe) {
      setIsOpen(true);
    }
  };

  const handleMenuClick = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const handleClick = (menuItem: MainMenuItem) => {
    if (isOpen) {
      setIsOpen(false);
      if (menuItem.href) {
        changeRoute(menuItem.href);
      }
    }
  };

  return (
    <div
      ref={ref}
      className={cn(
        {
          'translate-y-36': !isOpen && pathname !== '/',
        },
        'flex flex-col px-4 pb-4 bg-white rounded-t-xl w-full transition-transform',
        'duration-500 shadow-100 absolute bottom-0 left-0 z-20',
      )}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onClick={handleMenuClick}
      id="bottom-menu"
    >
      {!isHomePage ? (
        <div className="mt-1.5 mx-auto mb-3 w-16 h-1 bg-violet-200 rounded-2xl" />
      ) : null}

      <div className={cn({ 'mb-4': !isHomePage, 'mt-4': isHomePage }, 'flex justify-between mx-1')}>
        {mainMenu.map((menuItem, i) => (
          <div key={`main-menu-${i}`} className="flex flex-col items-center gap-y-2 cursor-pointer">
            <SquareButton
              variant={menuItem.variant}
              icon={menuItem.icon}
              onClick={() => handleClick(menuItem)}
            />
            <div className="text-small whitespace-pre-wrap">{menuItem.text}</div>
          </div>
        ))}
      </div>

      {!isHomePage ? (
        <Button href="/" variant="violet" text="Вернуться в меню" className="h-12 rounded-xl" />
      ) : null}
    </div>
  );
};
