import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { randomInteger } from 'src/utils';
import { useAppSelector } from 'src/hooks/redux';
import { HeadMenu } from 'src/components/HeadMenu';
import { routes } from 'src/config/routesData';
import { Menu } from 'src/components/Menu';
import { RouteItem } from 'src/config/types';
import { useLocation } from 'react-router-dom';

import cn from 'classnames';

interface ContainerMainProps {
  children?: ReactNode;
}

export const ContainerMain: FC<ContainerMainProps> = ({ children }) => {
  const { routeChangeTrigger } = useAppSelector(state => state.common);
  const { pathname } = useLocation();

  const bgColor = useMemo(() => {
    if (pathname.includes('advent-calendar')) return 'bg-blue-300';

    return 'bg-violet-100';
  }, [pathname]);

  useEffect(() => {
    setCoords(getCoords());
  }, [routeChangeTrigger]);

  const checkRouteWithId = (route: RouteItem) => {
    const lastPathnameSlash = pathname.substring(0, pathname.length - 1).lastIndexOf('/');
    const smallPathname = pathname.substring(0, lastPathnameSlash + 1);
    const smallRoute = route.path.split(':')[0];

    return smallPathname === smallRoute;
  };

  const removeLastRoute = (str: string) => {
    const smallStr = str.slice(0, -1);
    return smallStr.slice(0, smallStr.lastIndexOf('/') + 1);
  };

  const routeData = useMemo(() => {
    const route = routes.find(route =>
      route.withId ? checkRouteWithId(route) : route.path === pathname,
    );

    if (route) {
      return route;
    }
    const dynamicRoute = routes.find(route =>
      route.withId
        ? checkRouteWithId(route)
        : removeLastRoute(route.path) === removeLastRoute(pathname),
    );

    if (dynamicRoute) {
      return dynamicRoute;
    }
  }, [pathname]);

  const getCoords = () => {
    const position = randomInteger(0, 1) > 0.5;
    const first = {
      left: `${randomInteger(40, 60)}%`,
      top: position ? '-600px' : '80%',
      transform: `rotate(${randomInteger(40, 120)}deg)`,
    };

    const second = {
      left: `-${randomInteger(90, 120)}%`,
      top: !position ? '-600px' : '80%',
      transform: `rotate(${randomInteger(40, 120)}deg)`,
    };

    const third = {
      left: `${randomInteger(60, 90)}%`,
      top: !position ? '-600px' : '80%',
      transform: `rotate(${randomInteger(40, 120)}deg)`,
    };

    return [first, second, third];
  };

  const [coords, setCoords] = useState<{ left: string; top: string; transform: string }[]>(
    getCoords(),
  );

  return (
    <div className={cn('w-screen h-screen overflow-hidden relative', bgColor)}>
      {coords.map((cord, i) => (
        <div
          key={`bg-box-${i}`}
          className={cn(
            {
              'bg-lilac-300': i === 0,
              'bg-lilac-200': i === 1,
              'bg-lilac-100': i === 2,
            },
            'w-[44rem] h-[44rem] absolute opacity-30 rounded-[12.5rem] transition duration-300',
          )}
          style={cord}
        />
      ))}

      <div id="container-main" className="w-full h-full flex flex-col">
        {routeData && routeData.headMenuProps ? <HeadMenu {...routeData.headMenuProps} /> : null}

        <div
          className={cn(
            {
              'main-height': !routeData?.hideBottomMenu,
              'h-full': routeData?.hideBottomMenu,
            },
            'w-full overflow-auto flex flex-col relative z-10 scrollbar-hide',
          )}
        >
          {children}
        </div>

        {!routeData?.hideBottomMenu ? <Menu /> : null}
      </div>
    </div>
  );
};
