import React, { FC, useEffect } from 'react';
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import { useTelegram } from './hooks/useTelegram';
import { LocationProvider } from './components/LocationProvider';
import { Provider } from 'react-redux';
import { setupStore } from './store/store';
import { ContainerMain } from './containers';
import { routes } from './config/routesData';

const App: FC = () => {
  const { onExpand, disableClose } = useTelegram();

  const store = setupStore();

  useEffect(() => {
    onExpand();
    disableClose();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Provider store={store}>
          <LocationProvider>
            <ContainerMain>
              <Routes>
                {routes.map(({ path, element }) => (
                  <Route key={path} path={path} element={element} />
                ))}
              </Routes>
            </ContainerMain>
          </LocationProvider>
        </Provider>
      </BrowserRouter>
    </>
  );
};

export default App;
