import { CatalogItemType } from '../types';

export const wineTinderShopList: CatalogItemType[] = [
  {
    id: '1',
    img: '/static/images/wine-1.png',
    favourite: false,
    currentPrice: 4490,
    name: 'Вино Khvanchkara, Giuaani, 2020 г.',
    description: 'Хванчкара,\nГиуаани',
    count: 0,
  },
  {
    id: '2',
    img: '/static/images/wine-2.png',
    favourite: false,
    currentPrice: 1390,
    name: 'Вино Ojaleshi, Binekhi, 2021 г.',
    description: 'Оджалеши,\nБинехи',
    count: 0,
  },
  {
    id: '3',
    img: '/static/images/wine-3.png',
    favourite: true,
    currentPrice: 620,
    name: 'Вино Freschello Rosso Sweet Italy, Cielo',
    description: 'Фрескелло Россо Свит Итали, Чело',
    count: 0,
  },
  {
    id: '4',
    img: '/static/images/wine-4.png',
    favourite: false,
    currentPrice: 12943,
    oldPrice: 18490,
    discount: 30,
    name: 'Вино Kurni, Oasi degli Angeli, 2020 г.',
    description: 'Курни,\nОази дельи Анжели',
    count: 0,
  },
];
