import { FC } from 'react';
import { Button } from 'src/components/Button';
import { games } from 'src/config/data';
import { Container } from 'src/containers';

export const GamesHome: FC = () => {
  return (
    <Container classNames="gap-6">
      <div className="text-xs">Выберите подходящую игру</div>
      <div className="flex flex-col gap-4">
        {games.map(game => (
          <Button
            key={`game-${game.href}`}
            variant="violetOutline"
            text={game.name}
            href={game.href}
            className="rounded-lg w-full h-12"
          />
        ))}
      </div>
    </Container>
  );
};
