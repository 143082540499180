import { FC } from 'react';
import { ConstructorItemVariants } from '../../pages/Games/GameConstructor/GameConstructorDesc';
import cn from 'classnames';

import { ReactComponent as GIcon } from '../../assets/icons/constructor/g.svg';
import { ReactComponent as PIcon } from '../../assets/icons/constructor/p.svg';
import { ReactComponent as BIcon } from '../../assets/icons/constructor/b.svg';
import { ReactComponent as RIcon } from '../../assets/icons/constructor/r.svg';
import { ReactComponent as VIcon } from '../../assets/icons/constructor/v.svg';
import { ReactComponent as YIcon } from '../../assets/icons/constructor/y.svg';

interface ConstructorItemProps {
  value: ConstructorItemVariants;
  disable?: boolean;
  selected?: boolean;
  colored?: boolean;
  end?: boolean;
  checkHeight?: boolean;
  onClick?: (arg: ConstructorItemVariants) => void;
  points?: number;
}

export const ConstructorItem: FC<ConstructorItemProps> = ({
  value,
  disable,
  selected,
  colored,
  checkHeight,
  onClick,
  points,
  end,
}) => {
  const iconStyles = cn(
    { 'fill-white': colored && !disable, 'fill-grey-100': disable && !colored },
    'h-1/2 w-1/2',
  );

  const icon = {
    V: <VIcon className={cn({ 'fill-violet-200': !colored && !disable }, iconStyles)} />,
    B: <BIcon className={cn({ 'fill-blue-400': !colored && !disable }, iconStyles)} />,
    P: <PIcon className={cn({ 'fill-violet-600': !colored && !disable }, iconStyles)} />,
    Y: <YIcon className={cn({ 'fill-yellow-200': !colored && !disable }, iconStyles)} />,
    R: <RIcon className={cn({ 'fill-red-200': !colored && !disable }, iconStyles)} />,
    G: <GIcon className={cn({ 'fill-green-200': !colored && !disable }, iconStyles)} />,

    '': null,
    '-1': null,
  };

  const handleClick = () => {
    if (onClick && !disable) {
      onClick(value);
    }
  };

  return (
    <div
      onClick={handleClick}
      className={cn(
        {
          'bg-violet-200': value === 'V' && colored && !disable && !Boolean(end),
          'bg-blue-400': value === 'B' && colored && !disable && !Boolean(end),
          'bg-violet-600': value === 'P' && colored && !disable && !Boolean(end),
          'bg-yellow-200': value === 'Y' && colored && !disable && !Boolean(end),
          'bg-red-200': value === 'R' && colored && !disable && !Boolean(end),
          'bg-green-200': value === 'G' && colored && !disable && !Boolean(end),
          'bg-white': selected && !colored && !disable && !Boolean(end),
          'bg-white-50': !selected && !colored && !disable && !Boolean(end),
          'bg-violet-500 opacity-50': !disable && Boolean(end),
          'h-full': Boolean(checkHeight),
          'w-full': !Boolean(checkHeight),
          'border-blue-300': selected && Boolean(checkHeight) && !disable,
          'border-violet-300': selected && !Boolean(checkHeight),
        },
        'rounded border square flex items-center justify-center relative transition duration-300',
      )}
    >
      {points ? (
        <div
          className={
            'p-1 absolute -left-2.5 -top-2.5 text-blue-300 text-small ' +
            'bg-yellow-200 rounded-t rounded-bl'
          }
        >{`+${points}`}</div>
      ) : null}
      {icon[value]}
    </div>
  );
};
