import { FC, useCallback, useEffect, useState } from 'react';
import { ConstructorItem } from 'src/components/ConstructorItem';
import { Button } from 'src/components/Button';
import { createConstructorMatrix, placeConstructorItem } from 'src/utils/constructor';
import { winnerCalc } from 'src/utils/ticTacToe';
import { Info } from 'src/components/Info';
import cn from 'classnames';

import InfoBg8 from '../../../../assets/icons/info/info-bg-8.svg';
import { Container } from 'src/containers';

const cols = 8;
const rows = 8;

export type ConstructorItemVariants = 'V' | 'B' | 'P' | 'Y' | 'R' | 'G' | '-1' | '';

export const GameConstructorDesc: FC = () => {
  const [rate, setRate] = useState<number>(0);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [turn, setTurn] = useState<ConstructorItemVariants>('V');
  const [winnerList, setWinnerList] = useState<number[][]>([]);
  const [matrix, setMatrix] = useState<ConstructorItemVariants[][]>(
    createConstructorMatrix(rows, cols),
  );
  const [trigger, setTrigger] = useState(false);
  const [turnCounter, setTurnCounter] = useState<{ value: ConstructorItemVariants; count: number }>(
    {
      value: 'V',
      count: 0,
    },
  );

  useEffect(() => {
    if (winnerList.length) {
      setRate(prev => prev + winnerList.length);
      setTimeout(() => {
        setWinnerList([]);

        winnerList.forEach(el => {
          matrix[el[0]][el[1]] = '';
        });

        setMatrix(matrix);
        setWinnerList([]);
        setTrigger(prev => !prev);
      }, 500);
    }
  }, [winnerList, setWinnerList]);

  const handleClick = () => {
    if (isEnd) {
      handleRestart();
      return;
    }

    if (turnCounter.value === turn && turnCounter.count >= 2) {
      return;
    }

    const placedData = placeConstructorItem(matrix, turn);
    setMatrix(placedData.matrix);
    setTurnCounter(prev => ({ value: turn, count: prev.value === turn ? prev.count + 1 : 1 }));

    for (let i = 5; i > 2; i--) {
      const win = winnerCalc(matrix, rows, cols, i, placedData.lastRow, placedData.lastCol);
      if (win.winnerList.length) {
        setWinnerList(win.winnerList);
        break;
      }
      if (win.winner === '-1') {
        setIsEnd(true);
        break;
      }
    }
  };

  const handleRestart = () => {
    setMatrix(createConstructorMatrix(rows, cols));
    setTurn('V');
    setTurnCounter({ value: 'V', count: 0 });
    setIsEnd(false);
    setRate(0);
  };

  const createBoard = useCallback(() => {
    const board = [];

    for (let r = 0; r < rows; r++) {
      const row = [];
      for (let c = 0; c < cols; c++) {
        const isColored = winnerList.find(winnerItem => winnerItem[0] === r && winnerItem[1] === c);

        row.push(
          <ConstructorItem
            key={`tic-tac-toe-item-${r} + ${c}`}
            value={matrix[r][c]}
            selected={matrix[r][c] !== '' || matrix[r][c] !== '-1'}
            end={isEnd}
            colored={!!isColored}
            points={
              winnerList && winnerList?.[0]?.[0] === r && winnerList?.[0]?.[1] === c
                ? winnerList.length
                : 0
            }
          />,
        );
      }
      board.push(
        <div className="flex w-full" key={`tic-tac-toe-row-${r}`}>
          {row}
        </div>,
      );
    }

    return <div>{board}</div>;
  }, [isEnd, matrix, turn, cols, rows, winnerList, trigger]);

  return (
    <Container classNames="gap-3">
      {createBoard()}
      {!isEnd ? (
        <>
          <div
            className={cn(
              { 'opacity-0': !Boolean(rate) },
              'flex justify-between text-xs text-black-100',
            )}
          >
            <div>Ваш рейтинг:</div>
            <div>{rate}</div>
          </div>

          <div className="pt-3 border-t border-violet-400 w-full">
            <div className="font-inter-600 text-sm mb-2">Выберите фигуру:</div>
            <div className="flex justify-between h-10">
              {['V', 'B', 'P', 'Y', 'R', 'G'].map(el => (
                <ConstructorItem
                  key={el}
                  value={el as ConstructorItemVariants}
                  disable={turnCounter.value === el && turnCounter.count >= 2}
                  selected={el === turn}
                  checkHeight
                  onClick={value => {
                    if (value !== turn) setTurn(value);
                  }}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <Info
          title="Игра окончена"
          bg={InfoBg8}
          subtitle={`Всего вы набрали баллов\nРекорд 4 982 баллов`}
          rightText={rate.toString()}
        />
      )}

      <Button
        className={cn(
          { 'bg-grey-100': !!winnerList.length },
          'rounded-lg h-12 min-h-[3rem] mt-auto',
        )}
        text={isEnd ? 'Сыграть еще раз' : 'Поставить'}
        variant="red"
        disabled={!!winnerList.length}
        onClick={handleClick}
      />
    </Container>
  );
};
