import { FC } from 'react';
import { useRoute } from 'src/hooks/useRoute';
import { useParams } from 'react-router';
import cn from 'classnames';

export type ButtonVariants =
  | 'red'
  | 'redOutline'
  | 'violet'
  | 'violetOutline'
  | 'whiteOutline'
  | 'violetLightOutline';

interface ButtonProps {
  text: string;
  onClick?: () => void;
  href?: string;
  variant: ButtonVariants;
  className?: string;
  disabled?: boolean;
}

export const Button: FC<ButtonProps> = ({ variant, text, onClick, href, className, disabled }) => {
  const changeRoute = useRoute();
  const { id } = useParams();

  const variants = {
    red: 'bg-red-200 text-white',
    redOutline: 'text-red-200 border border-red-200 bg-white-50',
    violet: 'border border-blue-300 bg-violet-200 text-blue-300',
    violetOutline: 'border border-violet-500 bg-white-50',
    violetLightOutline: 'border border-violet-200',
    whiteOutline: 'border border-white bg-transparent text-white',
  };

  const handleClick = () => {
    if (disabled) {
      return;
    }

    if (onClick) {
      onClick();
    }

    if (href) {
      changeRoute(`${href}${id ? `${id}/` : ''}`);
    }
  };

  return (
    <div
      onClick={handleClick}
      className={cn(
        variants[variant],
        className,
        'flex items-center justify-center font-inter-600',
      )}
    >
      {text}
    </div>
  );
};
