import { FC } from 'react';
import { SmartestCategory } from 'src/components/SmartestCategory';
import { smartestCategories } from 'src/config/MOCK_DATA/gameSmartest';
import { Container } from 'src/containers';

export const GameSmartestCategories: FC = () => {
  return (
    <Container classNames="gap-2.5">
      <div className="text-sm font-inter-600">Выберите категорию</div>
      <div className="grid grid-cols-2 gap-2.5">
        {smartestCategories.map(el => (
          <SmartestCategory {...el} key={`smartest-category-${el.text}`} />
        ))}
      </div>
    </Container>
  );
};
