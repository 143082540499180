import { FC } from 'react';
import { DeliveryItem } from 'src/components/DeliveryItem';
import { DeliveryItemSmall } from 'src/components/DeliveryItemSmall';
import { Button } from 'src/components/Button';
import { Container } from 'src/containers';
import { FilterLine } from 'src/components/FilterLine';

export const DeliveryCourier: FC = () => {
  return (
    <Container classNames="gap-4">
      <FilterLine text="Курьерская доставка" />

      {[
        { title: 'Адрес:', text: 'г. Таганрог, Ростовская обл.' },
        { title: 'Дата доставки:', text: '30 декабря, пятница' },
        { title: 'Время доставки:', text: 'с 8:00 до 12:00' },
      ].map((el, i) => (
        <DeliveryItemSmall key={`delivery-item-small-${i}`} text={el.text} title={el.title} />
      ))}

      <DeliveryItem
        deliveryVariant="pickup"
        text={{
          lg: 'Ближайшее время доставки',
          md: '29 декабря, четверг',
          sm: 'Стоимость доставки 199 руб. вне зависимости от стоимости заказа',
        }}
      />

      <Button
        href="/delivery/payment/"
        text="Далее"
        variant="red"
        className="w-full h-12 rounded-xl mt-auto"
      />
    </Container>
  );
};
