import { RefObject, useEffect } from 'react';

type outerClickProps = {
  ref: RefObject<HTMLDivElement>;
  clickOutSide: () => void;
};

export const useOuterClick = ({ ref, clickOutSide }: outerClickProps) => {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const targetEl = e.target as Node;
      const currentEl = ref.current;

      if (currentEl && !currentEl?.contains(targetEl)) {
        clickOutSide();
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => document.removeEventListener('mousedown', handleClick);
  }, [clickOutSide, ref]);
};
