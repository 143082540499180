import { FC } from 'react';
import { TicTacToeItemVariants } from 'pages/Games/GameTicTacToe/GameTicTacToeDesc';
import cn from 'classnames';

import { ReactComponent as CloseIcon } from '../../assets/icons/kit/close.svg';
import { ReactComponent as ZeroIcon } from '../../assets/icons/kit/zero.svg';

interface TicTacToeItemProps {
  row: number;
  col: number;
  setValue: (row: number, col: number) => void;
  value: TicTacToeItemVariants;
  disable: boolean;
  selected: boolean;
  colored: boolean;
}

export const TicTacToeItem: FC<TicTacToeItemProps> = ({
  row,
  col,
  setValue,
  value,
  disable,
  selected,
  colored,
}) => {
  const icon = {
    X: (
      <CloseIcon
        className={cn({ 'stroke-red-200': !colored, 'stroke-white': colored }, 'h-1/2 w-1/2')}
      />
    ),
    O: (
      <ZeroIcon
        className={cn({ 'stroke-green-200': !colored, 'stroke-white': colored }, 'h-1/2 w-1/2')}
      />
    ),
    '': null,
    '-1': null,
  };

  const handleClick = () => {
    if (disable || selected) {
      return;
    }
    setValue(row, col);
  };

  return (
    <div
      onClick={handleClick}
      className={cn(
        {
          'bg-red-200': value === 'X' && colored,
          'bg-green-200': value === 'O' && colored,
          'bg-white': selected && !colored,
          'bg-white-50': !selected && !colored,
          'cursor-pointer': !disable && !selected,
          'cursor-default': disable || selected,
        },
        'rounded border border-violet-300 w-full square flex items-center justify-center',
      )}
    >
      {icon[value]}
    </div>
  );
};
