import { FC, ReactNode } from 'react';
import { useRoute } from 'src/hooks/useRoute';
import { useParams } from 'react-router';

interface AppLinkProps {
  href?: string;
  className?: string;
  children?: ReactNode;
}

export const AppLink: FC<AppLinkProps> = ({ href, className, children }) => {
  const changeRoute = useRoute();
  const { id } = useParams();

  const handleClick = () => {
    changeRoute(`${href}${id ? `${id}/` : ''}`);
  };

  return (
    <div onClick={handleClick} className={`cursor-pointer ${className}`}>
      {children}
    </div>
  );
};
