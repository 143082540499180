import { FC, useMemo } from 'react';
import { ProductSmall } from 'src/components/Cards/ProductSmall';
import { Button } from 'src/components/Button';
import { BasketPrice } from 'src/components/BasketPrice';
import { useParams } from 'react-router';
import { Container } from 'src/containers';
import { Promocode } from 'src/components/Promocode';
import { useAppSelector } from 'src/hooks/redux';
import cn from 'classnames';

export const CatalogBasket: FC = () => {
  const { products } = useAppSelector(state => state.products);
  const { id } = useParams();

  const isWineCatalog = id === 'wine';
  const data = useMemo(
    () => (id ? products?.[id].filter(el => el.count) || [] : []),
    [id, products],
  );

  return (
    <Container classNames="gap-5">
      <div>
        {data.map((el, i) => (
          <ProductSmall
            key={`product-small-${i}`}
            data={el}
            classNames={cn({
              'rounded-t-xl': !i,
              'rounded-b-xl': i === 3,
              'border-t border-violet-200': !!i,
            })}
            imgClassNames={isWineCatalog ? 'object-contain' : ''}
          />
        ))}
      </div>
      <Promocode />
      <BasketPrice />
      <Button
        href="/delivery/address/"
        text="Оформить заказ"
        variant="red"
        className="w-full h-12 min-h-[3rem] rounded-xl"
      />
    </Container>
  );
};
