import { CatalogItemType } from '../types';

export const mysteryBoxItems: CatalogItemType[] = [
  {
    id: '1',
    img: '/static/images/mystery-box-item.png',
    favourite: false,
    currentPrice: 2000,
    oldPrice: 2500,
    discount: 20,
    name: 'Подарочный набор натуральной косметики ',
    description: 'Пшеничный',
    count: 0,
  },
  {
    id: '2',
    img: '/static/images/mystery-box-item.png',
    favourite: false,
    currentPrice: 2000,
    oldPrice: 2500,
    discount: 20,
    name: 'Подарочный набор натуральной косметики ',
    description: 'Пшеничный',
    count: 0,
  },
  {
    id: '3',
    img: '/static/images/mystery-box-item.png',
    favourite: false,
    currentPrice: 2000,
    oldPrice: 2500,
    discount: 20,
    name: 'Подарочный набор натуральной косметики ',
    description: 'Пшеничный',
    count: 0,
  },
];
