import { useAppSelector } from './redux';

export const useTotals = (catalogId: string) => {
  const { products } = useAppSelector(state => state.products);
  const data = products?.[catalogId]?.filter(el => el.count) || [];
  let totalPrice = 0;
  let totalBenefit = 0;

  data.forEach(el => {
    totalPrice += el.count * el.currentPrice;

    totalBenefit += el.oldPrice ? el.count * (el.oldPrice - el.currentPrice) : 0;
  });

  return {
    totalCount: data.length,
    totalPrice,
    totalBenefit,
  };
};
