export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const debounce = (fn: (...args: any[]) => void, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const randomInteger = (min: number, max: number) =>
  Math.round(min - 0.5 + Math.random() * (max - min + 1));

export const splitArrayTo = (arr: any[], n: number) => {
  const count = Math.ceil(arr.length / n);

  return arr.reduce((p, c, i) => {
    if (i % count === 0) p.push({});
    p[p.length - 1][i] = c;
    return p;
  }, []);
};

export const numberWithSpaces = (number: string): string => {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};

export const checkClassName = (className: string, target: string) => {
  return className.includes(target);
};

export const stringToNumber = (str: string) => Number(str.match(/\d/g)?.join(''));
