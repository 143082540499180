import { FC } from 'react';
import { Button } from 'src/components/Button';

export const ConsultantCard: FC = () => {
  const characteristics = [
    'Образуется путем вращения круга вокруг своего диаметра на 180°.',
    'Различают два вида шаров: замкнутый – и открытый.',
    'Любое сечение шара плоскостью является кругом.',
  ];

  return (
    <div className="bg-white flex flex-col rounded-xl gap-4 p-2">
      <div className="rounded-md bg-violet-200 h-36 p-1.5">
        <img src="/static/images/ball.png" alt="" className="h-full w-full object-contain" />
      </div>

      <div className="flex flex-col gap-2">
        <div className="font-inter-500">Ваш выбор: шар</div>

        <div className="flex gap-2 text-xl">
          <div className="line-through text-violet-900 font-inter-500">2 500</div>
          <div className="text-red-200 font-inter-700">2 000 ₽</div>
        </div>

        <div className="text-xs text-blue-300">
          Шар – это , это совокупность точек, ограниченных сферой.
        </div>

        <div className="text-xs text-blue-300 flex flex-col gap-1.5">
          <div className="font-inter-700">Характеристики:</div>
          {characteristics.map(text => (
            <div className="flex">
              <div className="w-1 h-1 rounded-full mx-2 mt-1.5 bg-blue-300" />
              {text}
            </div>
          ))}
        </div>
      </div>
      <Button
        href="/"
        text="Скопировать ссылку на товар"
        variant="redOutline"
        className="w-full h-9 rounded-lg text-xs"
      />
    </div>
  );
};
