import { FC } from 'react';
import cn from 'classnames';
import { useTotals } from 'src/hooks/useTotals';
import { useParams } from 'react-router';

interface BasketPriceProps {
  className?: string;
}

export const BasketPrice: FC<BasketPriceProps> = ({ className }) => {
  const { id } = useParams();
  const { totalPrice, totalBenefit, totalCount } = useTotals(id || '');

  return (
    <div className={cn('w-full', className)}>
      <div className="w-full flex flex-col gap-2 py-2 border-y border-violet-400 text-small">
        <div className="flex justify-between">
          <div>Товаров в заказе</div>
          <div>{totalCount}</div>
        </div>
        <div className="flex justify-between">
          <div>Товаров на сумму</div>
          <div>{`${totalPrice} ₽`}</div>
        </div>
        <div className="flex justify-between">
          <div>Доставка</div>
          <div>199 ₽</div>
        </div>
        <div className="flex justify-between text-red-200">
          <div>Скидка</div>
          <div>{`-${totalBenefit} ₽`}</div>
        </div>
      </div>
      <div className="flex justify-between text-sm">
        <div className="font-inter-600">Итого:</div>
        <div className="font-inter-600">{`${totalPrice + 199} ₽`}</div>
      </div>
    </div>
  );
};
