import { FC } from 'react';
import { CatalogItemType } from 'src/config/types';
import { ProductCounter } from 'src/components/ProductCounter';
import { checkClassName } from 'src/utils';
import { useParams } from 'react-router';
import { useAppDispatch } from 'src/hooks/redux';
import { addItem, clearItem, removeItem } from 'src/store/products';
import cn from 'classnames';

import { ReactComponent as CloseIcon } from '../../../assets/icons/kit/close.svg';

interface ProductSmallProps {
  data: CatalogItemType;
  classNames?: string;
  imgClassNames?: string;
}

export const ProductSmall: FC<ProductSmallProps> = ({ data, classNames, imgClassNames }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const setItemParams = { catalogId: id || '', productId: data.id };

  const onAdd = () => {
    dispatch(addItem(setItemParams));
  };

  const onRemove = () => {
    dispatch(removeItem(setItemParams));
  };

  const onClear = () => {
    dispatch(clearItem(setItemParams));
  };

  return (
    <div className={cn('bg-white w-full py-3 p-2.5 flex gap-3', classNames)}>
      <img
        src={data.img}
        className={cn(
          { 'object-cover': !checkClassName(imgClassNames || '', 'object-') },
          'h-16 w-16 rounded-md',
          imgClassNames,
        )}
        alt=""
      />
      <div className="flex flex-col gap-0.5 w-full">
        <div className="flex w-full">
          <div className="text-black-100 font-inter-600 text-xs mr-auto">{data.name}</div>
          <div
            className="w-4 min-w-[1rem] h-4 flex items-center justify-center ml-2"
            onClick={onClear}
          >
            <CloseIcon className="stroke-grey-100 w-2 h-2" />
          </div>
        </div>
        <div className="text-black-100 opacity-50 text-small">{data.description}</div>
        <div className="flex justify-between items-center mt-auto">
          <div className="font-inter-700 text-lg">{data.currentPrice}</div>
          <ProductCounter count={data.count} onAdd={onAdd} onRemove={onRemove} small />
        </div>
      </div>
    </div>
  );
};
