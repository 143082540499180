import { FC, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import cn from 'classnames';
import { useRoute } from '../../hooks/useRoute';
import { useParams } from 'react-router';
import { useAppSelector } from 'src/hooks/redux';

export type SquareButtonVariants = 'red' | 'blue' | 'yellow' | 'purple' | 'green' | 'white';
export type SquareButtonSizes = 'md' | 'lg';

export interface SquareButtonProps {
  href?: string;
  onClick?: () => void;
  variant: SquareButtonVariants;
  icon: ReactNode;
  size?: SquareButtonSizes;
  stroke?: boolean;
  counter?: boolean;
  className?: string;
}

export const SquareButton: FC<SquareButtonProps> = ({
  href,
  onClick,
  variant,
  icon,
  size = 'md',
  stroke,
  counter,
  className,
}) => {
  const changeRoute = useRoute();
  const { products } = useAppSelector(state => state.products);

  const hrefInArr = window.location.href.split('/');
  const id = hrefInArr[hrefInArr.length - 2];

  const data = useMemo(
    () => (id ? products?.[id]?.filter(el => el.count) || [] : []),
    [id, products],
  );

  const variants = {
    white: 'border-white-100 bg-white',
    red: 'border-red-100 bg-red-200',
    blue: 'border-blue-100 bg-blue-200',
    yellow: 'border-yellow-100 bg-yellow-200',
    purple: 'border-purple-100 bg-purple-200',
    green: 'border-green-100 bg-green-200',
  };

  const sizes = {
    md: 'w-12 h-12 min-w-[3rem]',
    lg: 'w-14 h-14',
  };

  const handleClick = () => {
    if (href) {
      changeRoute(href);
      return;
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className={classNames(
        sizes[size],
        variants[variant],
        'rounded-2xl rotate-45 border-4 flex items-center justify-center relative m-1',
        className,
      )}
      onClick={handleClick}
    >
      {counter && data.length ? (
        <div
          className={
            '-rotate-45 absolute bottom-9 rounded-full flex items-center ' +
            'justify-center bg-red-200 text-small text-yellow-200 font-inter-600 ' +
            'w-5 h-5 z-10'
          }
        >
          {data.length}
        </div>
      ) : null}
      <div
        className={cn(
          {
            'fill-blue-300': !Boolean(stroke),
            'stroke-blue-300': Boolean(stroke),
          },
          '-rotate-45 w-5 h-5',
        )}
      >
        {icon}
      </div>
    </div>
  );
};
