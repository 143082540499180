import { FC, useState } from 'react';
import { BasketPrice } from 'src/components/BasketPrice';
import { Input } from 'src/components/Input';
import { Button } from 'src/components/Button';
import { FilterLine } from 'src/components/FilterLine';
import { Container } from 'src/containers';
import { Select, SelectedItemType } from 'src/components/Select';
import { useTotals } from 'src/hooks/useTotals';
import { useParams } from 'react-router';
import { useAppDispatch } from 'src/hooks/redux';
import { clearAll } from 'src/store/products';

export const DeliveryPayment: FC = () => {
  const { id } = useParams();
  const { totalPrice } = useTotals(id || '');
  const dispatch = useAppDispatch();

  const paymentTypes: SelectedItemType[] = [{ text: 'Наличными курьеру' }, { text: 'Картой' }];

  const [selectedPaymentType, setSelectedPaymentType] = useState<SelectedItemType | undefined>(
    paymentTypes[0],
  );

  const onClearAll = () => {
    dispatch(clearAll({ catalogId: id || '' }));
  };

  return (
    <Container classNames="gap-4">
      <FilterLine text="Тип оплаты" />

      <Select list={paymentTypes} onClick={setSelectedPaymentType} selected={selectedPaymentType} />

      <Input placeHolder="Приготовить сдачу" className="text-sm h-11" />

      <BasketPrice />

      <Button
        onClick={onClearAll}
        href="/delivery/success/"
        text={`Оформить заказ на ${totalPrice + 199} ₽`}
        variant="red"
        className="w-full h-12 rounded-xl mt-auto"
      />
    </Container>
  );
};
