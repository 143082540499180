import { createRef, FC, useEffect, useMemo, useRef, useState } from 'react';
import TinderCard from 'react-tinder-card';
import { TinderCard as Card } from '../Cards/TinderCard';
import { CatalogItemType } from 'src/config/types';

import cn from 'classnames';

export type TinderClickType = 'left' | 'right' | '';

interface TinderListProps {
  data: CatalogItemType[];
  click: TinderClickType;
  setClick: (arg: TinderClickType) => void;
  onEnd?: () => void;
}

export const TinderList: FC<TinderListProps> = ({ data, click, setClick, onEnd }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(data.length - 1);
  const [swipedList, setSwipedList] = useState<number[]>([]);
  const currentIndexRef = useRef(currentIndex);

  useEffect(() => {
    if (click) {
      swipe(click).then(() => setClick(''));
    }
  }, [click]);

  const childRefs: any = useMemo(
    () =>
      Array(data.length)
        .fill(0)
        .map(_ => createRef()),
    [],
  );

  const updateCurrentIndex = (val: number) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const canSwipe = currentIndex >= 0;

  const swiped = (index: number) => {
    updateCurrentIndex(index - 1);

    setTimeout(() => {
      setSwipedList(prev => [...prev, index]);
      if (index === 0 && onEnd) {
        onEnd();
      }
    }, 300);
  };

  const swipe = async (dir: string) => {
    if (canSwipe && currentIndex < data.length) {
      await childRefs?.[currentIndex]?.current?.swipe(dir);
    }
  };

  return (
    <>
      {data.map((el, i) => (
        <TinderCard
          key={`tinder-card-${i}`}
          swipeThreshold={0.5}
          ref={childRefs[i] as any}
          onSwipe={() => swiped(i)}
          className={cn({ hidden: swipedList.includes(i) }, 'absolute h-full inset-0')}
        >
          <Card data={el} classNames="w-full h-full" />
        </TinderCard>
      ))}
    </>
  );
};
