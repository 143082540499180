import { FC, useState } from 'react';
import { Button } from 'src/components/Button';
import { Container } from 'src/containers';
import { FilterLine } from 'src/components/FilterLine';
import { couponCategories } from 'src/config/MOCK_DATA/coupons';
import { Categories } from 'src/components/Categories';
import { Interval } from 'src/config/types';
import { MultiRangeSlider } from 'src/components/MultiRangeSlider';

export const CouponsFilter: FC = () => {
  const [selected, setSelected] = useState(['Полуфабрикаты']);
  const [sale, setSale] = useState<Interval>({ min: 10, max: 90 });

  const handleChangeSale = (sale: Interval) => {
    setSale(sale);
  };

  return (
    <>
      <FilterLine text="Категории" textClassNames="pt-5 px-6">
        <Categories
          uniqKey="coupon-category"
          list={couponCategories}
          selected={selected}
          setSelected={setSelected}
          rows={3}
          classNames="px-6"
        />
      </FilterLine>

      <Container>
        <FilterLine text="Скидка">
          <MultiRangeSlider
            values={sale}
            initValues={{ min: 0, max: 100 }}
            onChange={handleChangeSale}
            valueType="%"
          />
        </FilterLine>

        <Button
          href="/coupons/list/"
          text="Применить"
          variant="red"
          className="w-full h-12 rounded-xl mt-auto"
        />
      </Container>
    </>
  );
};
