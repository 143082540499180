import { FC, ReactNode } from 'react';
import cn from 'classnames';

interface FilterLineProps {
  text: string;
  children?: ReactNode;
  classNames?: string;
  textClassNames?: string;
}

export const FilterLine: FC<FilterLineProps> = ({ text, children, classNames, textClassNames }) => {
  return (
    <div className={cn('flex flex-col gap-2.5', classNames)}>
      <div className={cn('text-black-200 text-sm font-inter-600', textClassNames)}>{text}</div>
      {children}
    </div>
  );
};
