import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from './redux';
import { setRouteChangeTrigger } from 'src/store/common';

export const useRoute = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const containerMain = document.querySelector('#container-main');

  return (href?: string) => {
    if (containerMain) {
      dispatch(setRouteChangeTrigger());
      containerMain.className = `${containerMain.className} animate-fade-out`;

      setTimeout(() => {
        href ? navigate(href) : window.history.back();
        containerMain.className = containerMain.className.replace('out', 'in');
      }, 150);

      setTimeout(() => {
        containerMain.className = containerMain.className.replace(' animate-fade-in', '');
      }, 300);
    }
  };
};
