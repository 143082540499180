import { CatalogItemType } from '../types';

export const catalogProductsList: CatalogItemType[] = [
  {
    id: '1',
    img: '/static/images/milk.png',
    favourite: false,
    currentPrice: 254,
    oldPrice: 300,
    discount: 20,
    name: 'Батон хлеба',
    description: 'Пшеничный',
    count: 0,
  },
  {
    id: '2',
    img: '/static/images/milk.png',
    favourite: true,
    currentPrice: 100,
    oldPrice: 200,
    discount: 50,
    name: 'Молоко',
    description: 'Коровье',
    count: 0,
  },
  {
    id: '3',
    img: '/static/images/milk.png',
    favourite: false,
    currentPrice: 45,
    oldPrice: 50,
    discount: 10,
    name: 'Чипсы lays',
    description: 'С крабом',
    count: 0,
  },
  {
    id: '4',
    img: '/static/images/milk.png',
    favourite: false,
    currentPrice: 510,
    oldPrice: 600,
    discount: 15,
    name: 'Куриная грудка',
    description: 'Филе',
    count: 0,
  },
  {
    id: '5',
    img: '/static/images/milk.png',
    favourite: false,
    currentPrice: 150,
    oldPrice: 200,
    discount: 20,
    name: 'Пельмени',
    description: 'Вкусные',
    count: 0,
  },
];

export const catalogCosmeticList: CatalogItemType[] = [
  {
    id: '1',
    img: '/static/images/milk.png',
    favourite: false,
    currentPrice: 254,
    oldPrice: 300,
    discount: 20,
    name: 'Помада',
    description: 'Красная',
    count: 0,
  },
  {
    id: '2',
    img: '/static/images/milk.png',
    favourite: false,
    currentPrice: 200,
    oldPrice: 300,
    discount: 33,
    name: 'Шампунь',
    description: 'Женский',
    count: 0,
  },
];

export const catalogChemicalsList: CatalogItemType[] = [
  {
    id: '1',
    img: '/static/images/milk.png',
    favourite: false,
    currentPrice: 254,
    oldPrice: 300,
    discount: 20,
    name: 'Губка',
    description: 'Для посуды',
    count: 0,
  },
  {
    id: '2',
    img: '/static/images/milk.png',
    favourite: false,
    currentPrice: 254,
    oldPrice: 300,
    discount: 20,
    name: 'Порошок',
    description: 'Стиральный',
    count: 0,
  },
];

export const catalogCategories = [
  'Все акции',
  'Полуфабрикаты',
  'Молочные продукты',
  'Молочные продукты',
  'Молочные продукты',
  'Молочные продукты',
  'Рыба',
  'Напитки',
  'Выпечка',
  'Мясо',
];

export const catalogTypes = [
  {
    id: 'products',
    img: '/static/images/eg.png',
    text: 'Продукты',
    color: 'bg-yellow-200',
    href: '/catalog/list/products/',
  },
  {
    id: 'cosmetics',
    img: '/static/images/ocoil.png',
    text: 'Косметика',
    color: 'bg-red-200',
    href: '/catalog/list/cosmetics/',
  },
  {
    id: 'household-chemicals',
    img: '/static/images/bottle.png',
    text: 'Бытовая химия',
    color: 'bg-blue-200',
    href: '/catalog/list/household-chemicals/',
  },
];
