import { FC } from 'react';
import { AppLink } from 'src/components/AppLink';
import { Container } from 'src/containers';
import { catalogTypes } from 'src/config/MOCK_DATA/catalog';

import cn from 'classnames';

export const CatalogTypes: FC = () => {
  return (
    <>
      <Container classNames="gap-y-7">
        {catalogTypes.map((catalogType, i) => (
          <AppLink
            href={catalogType.href}
            key={`catalog-type-${i}`}
            className="w-full relative overflow-hidden h-44 rounded-xl flex w-full"
          >
            <img
              className="absolute object-cover w-full h-full left-0 top-0"
              src={catalogType.img}
              alt=""
            />
            <div
              className={cn(
                'absolute w-56 square rounded-[2rem] rotate-[26deg] -left-14 top-24',
                catalogType.color,
              )}
            />
            <div className="absolute left-3 bottom-3 font-inter-600 text-lg">
              {catalogType.text}
            </div>
          </AppLink>
        ))}
      </Container>
    </>
  );
};
