import { FC } from 'react';
import { Button } from 'src/components/Button';
import { wineTinderShopList } from 'src/config/MOCK_DATA/wineTinder';
import { Container } from 'src/containers';

export const WineTinderVoteEnd: FC = () => {
  const data = wineTinderShopList[0];

  return (
    <Container classNames="items-center gap-8">
      <img src="/static/images/wine-tinder-vote.svg" alt="" className="w-40 h-40 object-contain" />
      <div className="relative w-full rounded-xl bg-violet-600 p-3 overflow-hidden flex justify-between">
        <div className="absolute w-full square rounded-extra-large bg-yellow-200 -top-[160%] -left-[56%] rotate-45" />
        <div className="w-1/2 relative">
          <div className="text-xs">Больше голосов за:</div>
          <div className="text-sm font-inter-600">{data.name}</div>
        </div>
        <div className="text-white relative">
          <div className="text-xs">На сумму:</div>
          <div className="text-2xl font-inter-700">{data.currentPrice}</div>
        </div>
      </div>

      <div className="w-full mt-auto">
        <Button
          href="/catalog/basket/wine/"
          text="Перейти к покупке"
          variant="red"
          className="w-full h-12 rounded-lg mb-3"
        />
        <Button
          href="/wine-tinder/vote/result/"
          text="Посмотреть результаты"
          variant="violetOutline"
          className="w-full h-12 rounded-lg"
        />
      </div>
    </Container>
  );
};
