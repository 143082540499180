import { FC, useState } from 'react';
import { AdventCalendarType } from 'src/config/types';
import { Button } from '../../Button';

export const AdventCalendarCard: FC<AdventCalendarType> = ({ date, img, description }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => {
    if (!isOpen) setIsOpen(true);
  };

  return (
    <div
      className="cursor-pointer square w-full rounded-2xl border-2 border-violet-200 flex flex-col p-3"
      onClick={handleClick}
    >
      <div className="font-inter-600 text-xl text-white">{date}</div>
      {!isOpen ? (
        <img className="w-full object-contain max-h-[6rem] mt-auto" src={img} alt="" />
      ) : (
        <>
          <div className="text-white text-xs">{description}</div>
          <a className="text-blue-500 underline text-xs mb-1">Подробнее</a>
          <Button
            text="Забрать"
            variant="whiteOutline"
            className="h-7 rounded-2xl text-small mt-auto"
          />
        </>
      )}
    </div>
  );
};
