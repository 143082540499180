import { FC, useState } from 'react';
import { Button } from 'src/components/Button';
import { FilterLine } from 'src/components/FilterLine';
import { Categories } from 'src/components/Categories';
import { Select, SelectedItemType } from 'src/components/Select';
import { catalogCategories } from 'src/config/MOCK_DATA/catalog';
import { Container } from 'src/containers';
import { Interval } from 'src/config/types';
import { MultiRangeSlider } from 'src/components/MultiRangeSlider';

export const CatalogFilter: FC = () => {
  const shops: SelectedItemType[] = [{ text: 'Пятерочка' }, { text: 'Семерочка' }];
  const [selected, setSelected] = useState(['Полуфабрикаты']);
  const [price, setPrice] = useState<Interval>({ min: 100, max: 900 });
  const [sale, setSale] = useState<Interval>({ min: 10, max: 90 });
  const [selectedShop, setSelectedShop] = useState<SelectedItemType | undefined>(shops[0]);

  const handleChangePrice = (price: Interval) => {
    setPrice(price);
  };

  const handleChangeSale = (sale: Interval) => {
    setSale(sale);
  };

  return (
    <>
      <Container classNames="gap-4 h-auto overflow-">
        <FilterLine text="Выберите магазин">
          <Select list={shops} selected={selectedShop} onClick={setSelectedShop} />
        </FilterLine>
      </Container>

      <FilterLine text="Категории" textClassNames="px-6">
        <Categories
          uniqKey="shop-category"
          list={catalogCategories}
          selected={selected}
          setSelected={setSelected}
          rows={2}
          classNames="px-6"
        />
      </FilterLine>

      <Container classNames="gap-4 h-auto">
        <FilterLine text="Цена">
          <MultiRangeSlider
            values={price}
            initValues={{ min: 0, max: 1000 }}
            onChange={handleChangePrice}
            valueType=" ₽"
          />
        </FilterLine>

        <FilterLine text="Скидка">
          <MultiRangeSlider
            values={sale}
            initValues={{ min: 0, max: 100 }}
            onChange={handleChangeSale}
            valueType="%"
          />
        </FilterLine>

        <Button
          href="/catalog/list/"
          text="Применить"
          variant="red"
          className="w-full h-12 rounded-xl mt-auto"
        />
      </Container>
    </>
  );
};
