import { Dispatch, FC, ReactNode, SetStateAction, useMemo } from 'react';
import { CategoryItem } from '../CategoryItem';
import cn from 'classnames';
import { splitArrayTo } from '../../utils';

interface CategoriesProps {
  uniqKey: string;
  list: string[];
  selected?: string[];
  setSelected?: Dispatch<SetStateAction<string[]>>;
  rows?: number;
  classNames?: string;
}

export const Categories: FC<CategoriesProps> = ({
  uniqKey,
  list,
  selected,
  setSelected,
  rows = 1,
  classNames,
}) => {
  const handleClick = (el: string) => {
    if (setSelected) {
      setSelected(prev => (prev.includes(el) ? prev.filter(e => e !== el) : [...prev, el]));
    }
  };

  const arrays: { [key: number]: string }[] = useMemo(() => splitArrayTo(list, rows), [list, rows]);

  const renderRow = (row: { [key: number]: string }) => {
    const html: ReactNode[] = [];

    for (const key in row) {
      html.push(
        <CategoryItem
          key={`${uniqKey}-categories-item-${key}`}
          text={row[key]}
          selectedList={selected ? selected : true}
          onClick={handleClick}
        />,
      );
    }

    return html;
  };

  return (
    <div className={cn('w-full scrollbar-hide overflow-auto flex flex-col gap-2', classNames)}>
      {arrays.map((row, i) => (
        <div key={`${uniqKey}-categories-line-${i}`} className="flex gap-2">
          {renderRow(row)}
        </div>
      ))}
    </div>
  );
};
