import { FC, ReactNode } from 'react';
import { checkClassName } from 'src/utils';
import cn from 'classnames';

interface ContainerProps {
  classNames?: string;
  children?: ReactNode;
}

export const Container: FC<ContainerProps> = ({ classNames, children }) => {
  return (
    <div
      className={cn(
        {
          'flex-col': !checkClassName(classNames || '', 'flex-'),
          'px-6': !checkClassName(classNames || '', 'px-'),
          'py-5': !checkClassName(classNames || '', 'py-'),
          'overflow-auto': !checkClassName(classNames || '', 'overflow-'),
          'h-full': !checkClassName(classNames || '', 'h-'),
        },
        'flex scrollbar-hide',
        classNames,
      )}
    >
      {children}
    </div>
  );
};
