import { FC, ReactNode, useCallback, useRef, useState } from 'react';
import { useOuterClick } from 'src/hooks/useOuterClick';
import cn from 'classnames';

import { ReactComponent as ArrowFullIcon } from '../../assets/icons/kit/arrow-full.svg';

export type SelectedItemType = {
  text: string;
  icon?: ReactNode;
  type?: 'ASC' | 'DESC';
};

interface SelectProps {
  list: SelectedItemType[];
  selected?: SelectedItemType;
  placeholder?: string;
  onClick: (arg: SelectedItemType) => void;
  classNames?: string;
  height?: string;
}

export const Select: FC<SelectProps> = ({
  list,
  selected,
  placeholder,
  onClick,
  classNames,
  height,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState<boolean>(false);

  useOuterClick({ ref, clickOutSide: () => setOpen(false) });

  const styles = `${
    height || 'h-11'
  } flex items-center px-3.5 bg-white cursor-pointer text-xs text-black-200`;

  const viewItem = useCallback(
    (item: SelectedItemType) => (
      <>
        {item.icon ? <div className="mr-2">{item.icon}</div> : null}
        <div>{item.text}</div>
      </>
    ),
    [list, selected],
  );

  const handleClick = (item: SelectedItemType) => {
    setOpen(false);
    onClick(item);
  };

  return (
    <div className={cn('relative', classNames)} ref={ref}>
      <div
        className={cn(
          {
            'rounded-md': !open,
            'rounded-t-md': open,
          },
          'border border-violet-200',
          styles,
        )}
        onClick={() => setOpen(prev => !prev)}
      >
        {selected ? viewItem(selected) : <div className="opacity-30">{placeholder}</div>}
        <ArrowFullIcon className="ml-auto w-5 h-5 p-1" />
      </div>
      {open ? (
        <div
          className={cn(
            'z-50 rounded-b-md w-full border-x border-b max-h-[20rem] border-violet-300 overflow-auto absolute top-full left-0',
          )}
        >
          {list.map((selectItem, i) => (
            <div
              key={`select-item-${selectItem.text}-${i}`}
              onClick={() => handleClick(selectItem)}
              className={cn(
                { 'bg-violet-300': selectItem.text === selected?.text },
                'hover:bg-violet-300',
                styles,
              )}
            >
              {viewItem(selectItem)}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
