import { FC, ReactNode } from 'react';
import { SquareButton, SquareButtonVariants } from '../SquareButton';
import { Button } from '../Button';
import cn from 'classnames';

import { ReactComponent as TargetIcon } from '../../assets/icons/kit/target.svg';
import { ReactComponent as BoxIcon } from '../../assets/icons/kit/box.svg';
import { ReactComponent as CarIcon } from '../../assets/icons/kit/car.svg';

interface DeliveryItemProps {
  deliveryVariant?: 'pickup' | 'courier';
  className?: string;
  text: { lg: string; md?: string; sm?: string };
  onClick?: () => void;
}

export const DeliveryItem: FC<DeliveryItemProps> = ({
  deliveryVariant,
  className,
  text,
  onClick,
}) => {
  const deliveryVariants: {
    [key: string]: { icon: ReactNode; variant: SquareButtonVariants };
  } = {
    pickup: {
      icon: <BoxIcon />,
      variant: 'red',
    },
    courier: {
      icon: <CarIcon />,
      variant: 'yellow',
    },
  };

  return (
    <div
      className={cn('w-full p-4 rounded-xl border border-violet-200 flex items-center', className)}
      onClick={() => onClick && onClick()}
    >
      <SquareButton
        variant={!deliveryVariant ? 'purple' : deliveryVariants[deliveryVariant || ''].variant}
        icon={!deliveryVariant ? <TargetIcon /> : deliveryVariants[deliveryVariant || ''].icon}
        className="mr-3"
      />
      <div className="flex flex-col mr-auto">
        <div className="font-inter-600 text-small">{text.lg}</div>
        {text.md ? <div className="text-small">{text.md}</div> : null}
        {text.sm ? <div className="text-extra-medium mt-1 text-black-100">{text.sm}</div> : null}
      </div>
      {!deliveryVariant ? (
        <Button
          variant="redOutline"
          text="Добавить"
          className="h-7 w-20 ml-4 text-small rounded-md"
          href="/delivery/type/"
        />
      ) : null}
    </div>
  );
};
