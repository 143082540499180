import { FC, useState } from 'react';
import { DeliveryItem } from 'src/components/DeliveryItem';
import { Input } from 'src/components/Input';
import { Button } from 'src/components/Button';
import { Container } from 'src/containers';
import { Select, SelectedItemType } from 'src/components/Select';

import { ReactComponent as CalendarIcon } from '../../../assets/icons/kit/calendar.svg';
import { FilterLine } from 'src/components/FilterLine';

export const DeliveryDate: FC = () => {
  const times: SelectedItemType[] = [
    { text: 'с 8:00 до 12:00' },
    { text: 'с 12:00 до 15:00' },
    { text: 'с 15:00 до 17:00' },
    { text: 'с 17:00 до 21:00' },
  ];

  const [selectedTime, setSelectedTime] = useState<SelectedItemType | undefined>(times[0]);

  return (
    <Container classNames="gap-4">
      <FilterLine text="Дата доставки" />

      <DeliveryItem
        deliveryVariant="pickup"
        text={{
          lg: 'Ближайшее время доставки',
          md: '29 декабря, четверг',
          sm: 'Стоимость доставки 199 руб. вне зависимости от стоимости заказа',
        }}
      />

      <Input
        value="30 декабря"
        icon={<CalendarIcon className="w-5 h-5" />}
        className="text-sm h-11 font-inter-400"
      />

      <Select list={times} onClick={setSelectedTime} selected={selectedTime} />

      <Button
        href="/delivery/courier/"
        text="Далее"
        variant="red"
        className="w-full h-12 min-h-[3rem] rounded-xl mt-auto"
      />
    </Container>
  );
};
