import { FC } from 'react';
import { Sticker } from 'src/components/Cards/Sticker';
import { stickers } from 'src/config/MOCK_DATA/stickers';
import { Container } from 'src/containers';

export const StickersList: FC = () => {
  return (
    <Container classNames="gap-5">
      {stickers.map(sticker => (
        <Sticker key={`sticker-${sticker.id}`} data={sticker} />
      ))}
    </Container>
  );
};
