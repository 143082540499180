import { Game, MainMenuItem, NavigationItemType } from './types';

import { ReactComponent as FoodIcon } from '../assets/icons/kit/food.svg';
import { ReactComponent as MedalIcon } from '../assets/icons/kit/medal.svg';
import { ReactComponent as HumanIcon } from '../assets/icons/kit/human.svg';
import { ReactComponent as TimeIcon } from '../assets/icons/kit/time.svg';
import { ReactComponent as SendIcon } from '../assets/icons/kit/send.svg';
import { ReactComponent as TagIcon } from '../assets/icons/kit/tag.svg';
import { ReactComponent as CouponIcon } from '../assets/icons/kit/coupon.svg';
import { ReactComponent as StickerIcon } from '../assets/icons/kit/sticker.svg';
import { ReactComponent as AskIcon } from '../assets/icons/kit/ask.svg';
import { ReactComponent as PresentIcon } from '../assets/icons/kit/present.svg';
import { ReactComponent as OperatorIcon } from '../assets/icons/kit/operator.svg';
import { ReactComponent as TetrisIcon } from '../assets/icons/kit/tetris.svg';
import { ReactComponent as CalendarIcon } from '../assets/icons/kit/calendar.svg';
import { ReactComponent as HelpIcon } from '../assets/icons/kit/help.svg';
import { ReactComponent as WriteIcon } from '../assets/icons/kit/write.svg';
import { ReactComponent as CassaIcon } from '../assets/icons/kit/cassa.svg';
import { ReactComponent as CardIcon } from '../assets/icons/kit/card.svg';
import { ReactComponent as WineIcon } from '../assets/icons/kit/wine.svg';

export const mainMenu: MainMenuItem[] = [
  {
    text: 'Рецепты',
    variant: 'red',
    icon: <FoodIcon />,
    href: '/catalog/types/',
  },
  {
    text: 'Личный\nкабинет',
    variant: 'yellow',
    icon: <HumanIcon />,
    href: '/account/',
  },
  {
    text: 'Достижения',
    variant: 'blue',
    icon: <MedalIcon />,
    href: '/achievements/',
  },
  {
    text: 'Консьерж',
    variant: 'purple',
    icon: <TimeIcon />,
    href: '',
  },
];

export const navigation: NavigationItemType[] = [
  {
    text: 'Акции и скидки',
    bgColor: 'bg-purple-200',
    squareColor: 'bg-yellow-200',
    elements: [
      { variant: 'purple', text: 'Отправить\nчек', icon: <SendIcon />, href: '' },
      { variant: 'blue', text: 'Каталог\nскидок', icon: <TagIcon />, href: '/catalog/types/' },
      { variant: 'yellow', text: 'Активные\nкупоны', icon: <CouponIcon />, href: '/coupons/list/' },
      {
        variant: 'green',
        text: 'Наклейки\nдля акций',
        icon: <StickerIcon />,
        href: '/stickers/list/',
      },
      { variant: 'red', text: 'Условия\nакции', icon: <AskIcon />, href: '' },
    ],
  },
  {
    text: 'Подбор подарка',
    bgColor: 'bg-blue-500',
    squareColor: 'bg-red-200',
    elements: [
      {
        variant: 'purple',
        text: 'Mystery\nbox',
        icon: <PresentIcon />,
        href: '/mystery-box/for-who/',
      },
      {
        variant: 'blue',
        text: 'Online-\nконсультант',
        icon: <OperatorIcon />,
        href: '/online-consultant/home/',
      },
      {
        variant: 'red',
        text: 'Винный\nТиндер',
        icon: <WineIcon />,
        href: '/wine-tinder/home/',
      },
    ],
  },
  {
    text: 'Развлечения',
    bgColor: 'bg-yellow-200',
    squareColor: 'bg-green-200',
    elements: [
      { variant: 'yellow', text: 'Игры', icon: <TetrisIcon />, href: '/games/home/' },
      {
        variant: 'red',
        text: 'Адвент-\nкалендарь',
        icon: <CalendarIcon />,
        href: '/advent-calendar/',
      },
    ],
  },
  {
    text: 'Сервис',
    bgColor: 'bg-violet-200',
    squareColor: 'bg-yellow-200',
    elements: [
      { variant: 'red', text: 'Помощь', icon: <HelpIcon />, href: '' },
      { variant: 'blue', text: 'Написать\nмагазину', icon: <WriteIcon />, href: '' },
      { variant: 'yellow', text: 'Открыть\nкассу', icon: <CassaIcon />, href: '' },
      { variant: 'green', text: 'Карта\nлояльности', icon: <CardIcon />, href: '' },
    ],
  },
];

export const games: Game[] = [
  {
    href: '/games/tic-tac-toe/start/',
    name: 'Крестики-нолики',
  },
  {
    href: '/games/smartest/categories/',
    name: 'Самый умный',
  },
  {
    href: '/games/constructor/',
    name: 'Конструктор',
  },
];
