import React, { useCallback, useEffect, useState, useRef, FC } from 'react';
import { Interval } from 'src/config/types';
import { numberWithSpaces } from 'src/utils';
import cn from 'classnames';

interface MultiRangeSliderProps {
  values: Interval;
  initValues: Interval;
  valueType?: string;
  onChange: ({ min, max }: { min: number; max: number }) => void;
  classNames?: string;
}

export const MultiRangeSlider: FC<MultiRangeSliderProps> = ({
  values,
  initValues,
  valueType = '',
  onChange,
  classNames,
}) => {
  const { min, max } = values;

  const minRef = useRef(min);
  const maxRef = useRef(max);
  const range = useRef<HTMLDivElement>(null);
  const rangeMax = useRef<any>(max);
  const rangeMin = useRef<any>(min);
  const [minLimit] = useState(initValues.min);
  const [maxLimit] = useState(initValues.max);

  const getPercent = useCallback(
    (value: number) => Math.round(((value - minLimit) / (maxLimit - minLimit)) * 100),
    [min, max],
  );

  const minPercent = getPercent(min);
  const maxPercent = getPercent(max);

  useEffect(() => {
    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
      rangeMin.current.style.left = `${minPercent * 0.91}%`;
    }
  }, [min, getPercent]);

  useEffect(() => {
    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
      rangeMax.current.style.left = `${maxPercent - maxPercent * 0.11}%`;
    }
  }, [max, getPercent]);

  const moveStyles =
    'absolute text-small text-white px-2.5 py-1.5 bg-red-200 rounded top-6 whitespace-nowrap';
  const staticStyles = 'absolute top-2 text-small text-black-300';

  const inputParams = {
    type: 'range',
    min: minLimit,
    max: maxLimit,
    className: 'thumb',
  };

  const triangle = (
    <div
      className="rotate-45 w-2.5 h-2.5 bg-red-200 absolute"
      style={{ left: 'calc(50% - 5px)', top: '-5px' }}
    />
  );

  return (
    <div className={cn('relative mb-14', classNames)}>
      <input
        {...inputParams}
        value={min}
        onChange={event => {
          const value = Math.min(Number(event.target.value), max - 1);
          minRef.current = value;
          onChange({ min: value, max });
        }}
        {...(min > max - 100 && { style: { zIndex: 5 } })}
      />
      <input
        {...inputParams}
        value={max}
        onChange={event => {
          const value = Math.max(Number(event.target.value), min + 1);
          maxRef.current = value;
          onChange({ min: min, max: value });
        }}
      />
      <div className={cn('left-0', staticStyles)}>{`${numberWithSpaces(initValues.min.toString())}${
        initValues.min ? valueType : ''
      }`}</div>
      <div className={cn('right-0', staticStyles)}>{`${numberWithSpaces(
        initValues.max.toString(),
      )}${valueType}`}</div>

      <div className="relative w-full flex justify-center">
        <div className="w-full absolute h-1 bg-violet-300" />
        <div ref={range} className="absolute h-1 bg-red-200" />

        <div ref={rangeMin} className={cn('-ml-2', moveStyles)}>
          {triangle}
          {`${numberWithSpaces(min.toString())}${min ? valueType : ''}`}
        </div>

        <div ref={rangeMax} className={cn('ml-1', moveStyles)}>
          {triangle}
          {`${numberWithSpaces(max.toString())}${valueType}`}
        </div>
      </div>
    </div>
  );
};
